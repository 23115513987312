export interface FinancialMetricProps {
  title: string;
  legends: {
    name: string;
    color: string;
  }[];
  donutChart: {
    id: string;
    value: number;
    label: string;
    valueColor: string;
  };
  items: FinancialMetricExpandableItemProps[];
}

export interface FinancialMetricExpandableItemProps {
  name: string;
  value: number;
  color: string;
  level: number;
  items: FinancialMetricExpandableItemProps[];
}

export const STORY_DATA_VARIANT_1: FinancialMetricProps = {
  title: "Budget Utilisation",
  legends: [
    {
      name: "Disbursement + Cash balance",
      color: "#013E77",
    },
    {
      name: "Budget",
      color: "#CFD4DA",
    },
  ],
  donutChart: {
    id: "budget-utilisation-donut-chart",
    value: 103.25936338903949,
    label: "utilised",
    valueColor: "#013E77",
  },
  items: [
    {
      level: 0,
      name: "Civil Society Organization",
      value: 102.19829933981165,
      color: "#013E77",
      items: [
        {
          level: 1,
          name: "AIDS Foundation South Africa",
          value: 107.3030329345739,
          color: "#013E77",
          items: [],
        },
        {
          level: 1,
          name: "ActionAid International Malawi",
          value: 102.77303589186704,
          color: "#013E77",
          items: [],
        },
        {
          level: 1,
          name: "ActionAid International The Gambia",
          value: 104.04331799327453,
          color: "#013E77",
          items: [],
        },
        {
          level: 1,
          name: "Alliance Nationale pour la Santé et le Développement en Côte D'Ivoire",
          value: 103.04894457263872,
          color: "#013E77",
          items: [],
        },
        {
          level: 1,
          name: "Alter Vida - Centro de Estudios y Formación para el Ecodesarrollo",
          value: 100,
          color: "#013E77",
          items: [],
        },
        {
          level: 1,
          name: "Amref Health Africa",
          value: 102.71361782472157,
          color: "#013E77",
          items: [],
        },
        {
          level: 1,
          name: "Association pour Résilience des Communautés vers l’Accès au Développement et à la Santé Plus",
          value: 100,
          color: "#013E77",
          items: [],
        },
        {
          level: 1,
          name: "BRAC",
          value: 104.38433945582975,
          color: "#013E77",
          items: [],
        },
        {
          level: 1,
          name: "Beyond Zero NPC",
          value: 104.07187874094355,
          color: "#013E77",
          items: [],
        },
        {
          level: 1,
          name: "CARE INTERNATIONAL CAMEROON",
          value: 100,
          color: "#013E77",
          items: [],
        },
        {
          level: 1,
          name: "CARE Peru",
          value: 100,
          color: "#013E77",
          items: [],
        },
        {
          level: 1,
          name: "Cameroon National Association for Family Welfare",
          value: 103.32752323170283,
          color: "#013E77",
          items: [],
        },
        {
          level: 1,
          name: "Caribbean Community (CARICOM)",
          value: 103.35818860151807,
          color: "#013E77",
          items: [],
        },
        {
          level: 1,
          name: "Catholic Relief Services - United States Conference of Catholic Bishops",
          value: 100.62600220805507,
          color: "#013E77",
          items: [],
        },
        {
          level: 1,
          name: "Center for Health Policies and Studies",
          value: 100,
          color: "#013E77",
          items: [],
        },
        {
          level: 1,
          name: "Central Board of ‘Aisyiyah",
          value: 105.30600137583758,
          color: "#013E77",
          items: [],
        },
        {
          level: 1,
          name: "Centre de Recherches Médicales de Lambaréné",
          value: 100.5280762096242,
          color: "#013E77",
          items: [],
        },
        {
          level: 1,
          name: "Centre for Health Research and Innovation",
          value: 100,
          color: "#013E77",
          items: [],
        },
        {
          level: 1,
          name: "Centro de Colaboração em Saúde",
          value: 102.01290425069934,
          color: "#013E77",
          items: [],
        },
        {
          level: 1,
          name: "Centro de Información y Recursos para el Desarrollo",
          value: 106.41788852267118,
          color: "#013E77",
          items: [],
        },
        {
          level: 1,
          name: 'Charitable Organization "All-Ukrainian Network of People Living with HIV/AIDS"',
          value: 104.39295182499693,
          color: "#013E77",
          items: [],
        },
        {
          level: 1,
          name: "Christian Health Association of Ghana",
          value: 100,
          color: "#013E77",
          items: [],
        },
        {
          level: 1,
          name: "Churches Health Association of Zambia",
          value: 101.97727833475676,
          color: "#013E77",
          items: [],
        },
        {
          level: 1,
          name: "Community Development Fund",
          value: 103.93166279534452,
          color: "#013E77",
          items: [],
        },
        {
          level: 1,
          name: "Cooperative Housing Foundation",
          value: 103.9336949786333,
          color: "#013E77",
          items: [],
        },
        {
          level: 1,
          name: "Coordinating Assembly of Non Governmental Organisation",
          value: 104.83693047168308,
          color: "#013E77",
          items: [],
        },
        {
          level: 1,
          name: "East, Central and Southern Africa Health Community",
          value: 103.23677478402193,
          color: "#013E77",
          items: [],
        },
        {
          level: 1,
          name: "Family Health International (FHI360)",
          value: 101.14839267009496,
          color: "#013E77",
          items: [],
        },
        {
          level: 1,
          name: "Federación Red NICASALUD",
          value: 101.57023543251017,
          color: "#013E77",
          items: [],
        },
        {
          level: 1,
          name: "Foundation for Innovative New Diagnostics India",
          value: 103.17884262391985,
          color: "#013E77",
          items: [],
        },
        {
          level: 1,
          name: "Frontline AIDS",
          value: 106.63468515838805,
          color: "#013E77",
          items: [],
        },
        {
          level: 1,
          name: "Fundação para o Desenvolvimento da Comunidade",
          value: 100.62079844813532,
          color: "#013E77",
          items: [],
        },
        {
          level: 1,
          name: "Ghana-West Africa Program to Combat AIDS - STI",
          value: 101.36587933786383,
          color: "#013E77",
          items: [],
        },
        {
          level: 1,
          name: "Humanist Institute for Co-operation with Developing Countries",
          value: 105.45184498432644,
          color: "#013E77",
          items: [],
        },
        {
          level: 1,
          name: "Humanist Institute for Co-operation with Developing Countries (Hivos)",
          value: 102.14417076707838,
          color: "#013E77",
          items: [],
        },
        {
          level: 1,
          name: "India HIV/AIDS Alliance",
          value: 105.80658537817722,
          color: "#013E77",
          items: [],
        },
        {
          level: 1,
          name: "Initiative Privée et Communautaire pour la Santé et la Riposte au VIH/SIDA  au Burkina Faso (IPC/BF)",
          value: 100.91322211877178,
          color: "#013E77",
          items: [],
        },
        {
          level: 1,
          name: "Institute of Human Virology Nigeria",
          value: 102.6890359122588,
          color: "#013E77",
          items: [],
        },
        {
          level: 1,
          name: "Instituto Dermatológico y Cirugía de Piel ‘Dr. Huberto Bogaert Díaz’",
          value: 106.80955799833252,
          color: "#013E77",
          items: [],
        },
        {
          level: 1,
          name: "International Centre for Diarrhoeal Disease Research, Bangladesh",
          value: 102.55375010383783,
          color: "#013E77",
          items: [],
        },
        {
          level: 1,
          name: "International Charitable Foundation “Alliance for Public Health”",
          value: 105.82355661905653,
          color: "#013E77",
          items: [],
        },
        {
          level: 1,
          name: "International Union Against Tuberculosis and Lung Disease",
          value: 109.39656460444327,
          color: "#013E77",
          items: [],
        },
        {
          level: 1,
          name: "Kenya Red Cross Society",
          value: 100.50809805293707,
          color: "#013E77",
          items: [],
        },
        {
          level: 1,
          name: "Konsorsium Komunitas PENABULU-STPI",
          value: 100,
          color: "#013E77",
          items: [],
        },
        {
          level: 1,
          name: "La Croix-Rouge française",
          value: 100,
          color: "#013E77",
          items: [],
        },
        {
          level: 1,
          name: "Management Sciences for Health",
          value: 100,
          color: "#013E77",
          items: [],
        },
        {
          level: 1,
          name: "Mercy Corps",
          value: 105.5608984704561,
          color: "#013E77",
          items: [],
        },
        {
          level: 1,
          name: "Nai Zindagi",
          value: 101.35494240076983,
          color: "#013E77",
          items: [],
        },
        {
          level: 1,
          name: "Namibia Network of AIDS Service Organizations",
          value: 102.26142317106306,
          color: "#013E77",
          items: [],
        },
        {
          level: 1,
          name: "Networking HIV and AIDS Community of Southern Africa NPC",
          value: 112.14082049486478,
          color: "#013E77",
          items: [],
        },
        {
          level: 1,
          name: "Organisation of Eastern Caribbean States",
          value: 100.67130578348498,
          color: "#013E77",
          items: [],
        },
        {
          level: 1,
          name: "Organismo Andino de Salud - Convenio Hipólito Unanue",
          value: 103.87251179028254,
          color: "#013E77",
          items: [],
        },
        {
          level: 1,
          name: "Pact, Inc.",
          value: 107.96925788983636,
          color: "#013E77",
          items: [],
        },
        {
          level: 1,
          name: "Partners In Health",
          value: 100,
          color: "#013E77",
          items: [],
        },
        {
          level: 1,
          name: "Persatuan Karya Dharma Kesehatan Indonesia (also known as “PERDHAKI”, Association of Voluntary Health Services of Indonesia)",
          value: 104.41893612919517,
          color: "#013E77",
          items: [],
        },
        {
          level: 1,
          name: "Philippine Business for Social Progress, Inc.",
          value: 101.58121666105725,
          color: "#013E77",
          items: [],
        },
        {
          level: 1,
          name: "Plan International (India Chapter)",
          value: 101.44290566862605,
          color: "#013E77",
          items: [],
        },
        {
          level: 1,
          name: "Plan International El Salvador",
          value: 100,
          color: "#013E77",
          items: [],
        },
        {
          level: 1,
          name: "Plan International, Inc.",
          value: 102.29328730735709,
          color: "#013E77",
          items: [],
        },
        {
          level: 1,
          name: "Population Services International",
          value: 102.55365116281756,
          color: "#013E77",
          items: [],
        },
        {
          level: 1,
          name: "Prévention Information Lutte contre le Sida",
          value: 107.95482607841261,
          color: "#013E77",
          items: [],
        },
        {
          level: 1,
          name: "Raks Thai Foundation",
          value: 102.2392885727168,
          color: "#013E77",
          items: [],
        },
        {
          level: 1,
          name: "SADC Malaria Elimination Eight Secretariat",
          value: 107.00977925189146,
          color: "#013E77",
          items: [],
        },
        {
          level: 1,
          name: "SANRU Asbl",
          value: 102.1892942321897,
          color: "#013E77",
          items: [],
        },
        {
          level: 1,
          name: "Save the Children Federation, Inc.",
          value: 102.79567921490131,
          color: "#013E77",
          items: [],
        },
        {
          level: 1,
          name: "Society For Family Health",
          value: 100,
          color: "#013E77",
          items: [],
        },
        {
          level: 1,
          name: "Socios en Salud sucursal Peru",
          value: 107.26142782921413,
          color: "#013E77",
          items: [],
        },
        {
          level: 1,
          name: "Solidarity and Action Against The HIV Infection in India",
          value: 101.29034134556498,
          color: "#013E77",
          items: [],
        },
        {
          level: 1,
          name: "St. Petersburg charitable fund programs “Humanitarian action”",
          value: 100,
          color: "#013E77",
          items: [],
        },
        {
          level: 1,
          name: "Stichting Cordaid",
          value: 100.19500721404229,
          color: "#013E77",
          items: [],
        },
        {
          level: 1,
          name: "T.C.I. Foundation",
          value: 100,
          color: "#013E77",
          items: [],
        },
        {
          level: 1,
          name: "The AIDS Support Organisation (Uganda) Limited",
          value: 101.4682062110297,
          color: "#013E77",
          items: [],
        },
        {
          level: 1,
          name: "The Family Planning Association of Sri Lanka",
          value: 110.4174362221788,
          color: "#013E77",
          items: [],
        },
        {
          level: 1,
          name: "Viet Nam Union of Science and Technology Associations",
          value: 103.88702158837705,
          color: "#013E77",
          items: [],
        },
        {
          level: 1,
          name: "World Vision (PNG) Trust",
          value: 102.9514701470876,
          color: "#013E77",
          items: [],
        },
        {
          level: 1,
          name: "World Vision International",
          value: 101.36051688068034,
          color: "#013E77",
          items: [],
        },
        {
          level: 1,
          name: "World Vision Malawi",
          value: 101.15403648270738,
          color: "#013E77",
          items: [],
        },
        {
          level: 1,
          name: "Yayasan Spiritia",
          value: 102.38173836727675,
          color: "#013E77",
          items: [],
        },
      ],
    },
    {
      level: 0,
      name: "Governmental Organization",
      value: 103.26617090662428,
      color: "#013E77",
      items: [
        {
          level: 1,
          name: "Alliance Nationale des Communautés pour la Santé - Sénégal",
          value: 103.91309032718281,
          color: "#013E77",
          items: [],
        },
        {
          level: 1,
          name: "Australian Federation Of AIDS Organisations Limited",
          value: 100,
          color: "#013E77",
          items: [],
        },
        {
          level: 1,
          name: "Botswana-Ministry of Health",
          value: 101.64991524564955,
          color: "#013E77",
          items: [],
        },
        {
          level: 1,
          name: "Cambodia Ministry of Economy and Finance",
          value: 101.74927811048246,
          color: "#013E77",
          items: [],
        },
        {
          level: 1,
          name: "Cellule Nationale de Coordination Technique de la Riposte Nationale au Sida et aux Hépatites",
          value: 101.20619622124379,
          color: "#013E77",
          items: [],
        },
        {
          level: 1,
          name: "Conseil National de Lutte contre le SIDA de la République du Sénégal",
          value: 100.80758146512146,
          color: "#013E77",
          items: [],
        },
        {
          level: 1,
          name: "Conseil National de Lutte contre le VIH/SIDA, la Tuberculose, le Paludisme, les Hépatites, les Infections sexuellement transmissibles et les Epidémies",
          value: 100,
          color: "#013E77",
          items: [],
        },
        {
          level: 1,
          name: "Consejo Nacional para el VIH y el SIDA",
          value: 113.65193747387148,
          color: "#013E77",
          items: [],
        },
        {
          level: 1,
          name: "Coordination Committee of the Fight against AIDS of Cabo Verde",
          value: 100.30215857522845,
          color: "#013E77",
          items: [],
        },
        {
          level: 1,
          name: "Department of Disease Control, Ministry of Public Health of the Royal Government of Thailand",
          value: 104.55089876200958,
          color: "#013E77",
          items: [],
        },
        {
          level: 1,
          name: "Department of Economic Affairs, Ministry of Finance of India",
          value: 104.07169719530995,
          color: "#013E77",
          items: [],
        },
        {
          level: 1,
          name: "Directorate General of Disease Prevention and Control, Ministry of Health of The Republic of Indonesia",
          value: 103.9772709362492,
          color: "#013E77",
          items: [],
        },
        {
          level: 1,
          name: "Directorate of Malaria Control, Ministry of National Health Services, Regulations and Coordination of Pakistan",
          value: 101.72190409802494,
          color: "#013E77",
          items: [],
        },
        {
          level: 1,
          name: "Economic Relations Division, Ministry of Finance of the People's Republic of Bangladesh",
          value: 101.66288276990232,
          color: "#013E77",
          items: [],
        },
        {
          level: 1,
          name: "Empresa Nacional Promotora del Desarrollo Territorial",
          value: 108.61597510276107,
          color: "#013E77",
          items: [],
        },
        {
          level: 1,
          name: "Federal Ministry of Health of the Federal Democratic Republic of Ethiopia",
          value: 110.34978947158017,
          color: "#013E77",
          items: [],
        },
        {
          level: 1,
          name: "Federal Ministry of Health of the Republic of Sudan",
          value: 101.34114862081648,
          color: "#013E77",
          items: [],
        },
        {
          level: 1,
          name: "HIV/AIDS Prevention and Control Office",
          value: 103.16514245542834,
          color: "#013E77",
          items: [],
        },
        {
          level: 1,
          name: "Indonesia AIDS Coalition",
          value: 100,
          color: "#013E77",
          items: [],
        },
        {
          level: 1,
          name: "Instituto Nicaragüense de Seguridad Social",
          value: 140.6261463241232,
          color: "#013E77",
          items: [],
        },
        {
          level: 1,
          name: "Intergovernmental Authority on Development",
          value: 104.01982881925365,
          color: "#013E77",
          items: [],
        },
        {
          level: 1,
          name: "Kazakh scientific center of dermatology and infectious diseases of the Ministry of Health of the Republic of Kazakhstan",
          value: 107.149942900608,
          color: "#013E77",
          items: [],
        },
        {
          level: 1,
          name: "Lagos State Ministry of Health",
          value: 101.91766465758343,
          color: "#013E77",
          items: [],
        },
        {
          level: 1,
          name: "Lubombo Spatial Development Initiative 2 NPC",
          value: 100.1594231559097,
          color: "#013E77",
          items: [],
        },
        {
          level: 1,
          name: "Malaysian AIDS Council",
          value: 100.43406248679578,
          color: "#013E77",
          items: [],
        },
        {
          level: 1,
          name: "Ministry of Finance of the Kingdom of Lesotho",
          value: 101.13588310240841,
          color: "#013E77",
          items: [],
        },
        {
          level: 1,
          name: "Ministry of Finance of the United Republic of Tanzania",
          value: 104.49317762213526,
          color: "#013E77",
          items: [],
        },
        {
          level: 1,
          name: "Ministry of Finance, Planning and Economic Development of the Republic of Uganda",
          value: 102.48564052933502,
          color: "#013E77",
          items: [],
        },
        {
          level: 1,
          name: "Ministry of Health",
          value: 102.57728914340522,
          color: "#013E77",
          items: [],
        },
        {
          level: 1,
          name: "Ministry of Health and Social Action of the Republic of Senegal",
          value: 100.56493489256384,
          color: "#013E77",
          items: [],
        },
        {
          level: 1,
          name: "Ministry of Health and Social Assistance of the Republic of Guatemala",
          value: 110.61009978559599,
          color: "#013E77",
          items: [],
        },
        {
          level: 1,
          name: "Ministry of Health and Social Development of the Republic of Mali",
          value: 100,
          color: "#013E77",
          items: [],
        },
        {
          level: 1,
          name: "Ministry of Health and Social Protection of the Republic of Albania",
          value: 128.23982270439353,
          color: "#013E77",
          items: [],
        },
        {
          level: 1,
          name: "Ministry of Health and Social Services of Namibia",
          value: 103.34338395019725,
          color: "#013E77",
          items: [],
        },
        {
          level: 1,
          name: "Ministry of Health and Social Welfare",
          value: 100.1496082150807,
          color: "#013E77",
          items: [],
        },
        {
          level: 1,
          name: "Ministry of Health and Wellness",
          value: 115.28076335206359,
          color: "#013E77",
          items: [],
        },
        {
          level: 1,
          name: "Ministry of Health and Wellness,  Jamaica",
          value: 103.29233909869255,
          color: "#013E77",
          items: [],
        },
        {
          level: 1,
          name: "Ministry of Health of Mongolia",
          value: 103.17810251341936,
          color: "#013E77",
          items: [],
        },
        {
          level: 1,
          name: "Ministry of Health of Montenegro",
          value: 103.63323667088504,
          color: "#013E77",
          items: [],
        },
        {
          level: 1,
          name: "Ministry of Health of Mozambique",
          value: 101.21815220111998,
          color: "#013E77",
          items: [],
        },
        {
          level: 1,
          name: "Ministry of Health of Republic of Serbia",
          value: 104.7966020844768,
          color: "#013E77",
          items: [],
        },
        {
          level: 1,
          name: "Ministry of Health of Romania",
          value: 100,
          color: "#013E77",
          items: [],
        },
        {
          level: 1,
          name: "Ministry of Health of Sao Tome and Principe",
          value: 100,
          color: "#013E77",
          items: [],
        },
        {
          level: 1,
          name: "Ministry of Health of the Co-operative Republic of Guyana",
          value: 130.46284847198893,
          color: "#013E77",
          items: [],
        },
        {
          level: 1,
          name: "Ministry of Health of the Democratic Republic of Timor-Leste",
          value: 101.7584661137124,
          color: "#013E77",
          items: [],
        },
        {
          level: 1,
          name: "Ministry of Health of the Democratic Socialist  Republic of Sri Lanka",
          value: 112.0721364452433,
          color: "#013E77",
          items: [],
        },
        {
          level: 1,
          name: "Ministry of Health of the Kingdom of Morocco",
          value: 102.27502774676726,
          color: "#013E77",
          items: [],
        },
        {
          level: 1,
          name: "Ministry of Health of the Lao People's Democratic Republic",
          value: 105.37139639500339,
          color: "#013E77",
          items: [],
        },
        {
          level: 1,
          name: "Ministry of Health of the Republic of Angola",
          value: 100.71862080536258,
          color: "#013E77",
          items: [],
        },
        {
          level: 1,
          name: "Ministry of Health of the Republic of Armenia",
          value: 103.81725160531363,
          color: "#013E77",
          items: [],
        },
        {
          level: 1,
          name: "Ministry of Health of the Republic of Azerbaijan",
          value: 103.52290788453796,
          color: "#013E77",
          items: [],
        },
        {
          level: 1,
          name: "Ministry of Health of the Republic of El Salvador",
          value: 123.35627776461212,
          color: "#013E77",
          items: [],
        },
        {
          level: 1,
          name: "Ministry of Health of the Republic of Ghana",
          value: 101.98338571740548,
          color: "#013E77",
          items: [],
        },
        {
          level: 1,
          name: "Ministry of Health of the Republic of Guinea-Bissau",
          value: 100.09679446123312,
          color: "#013E77",
          items: [],
        },
        {
          level: 1,
          name: "Ministry of Health of the Republic of Malawi",
          value: 100.62840783572501,
          color: "#013E77",
          items: [],
        },
        {
          level: 1,
          name: "Ministry of Health of the Republic of Rwanda",
          value: 100,
          color: "#013E77",
          items: [],
        },
        {
          level: 1,
          name: "Ministry of Health of the Republic of Suriname",
          value: 101.80898504618013,
          color: "#013E77",
          items: [],
        },
        {
          level: 1,
          name: "Ministry of Health of the Republic of The Gambia",
          value: 101.709170825323,
          color: "#013E77",
          items: [],
        },
        {
          level: 1,
          name: "Ministry of Health of the Republic of Zambia",
          value: 105.39990031242536,
          color: "#013E77",
          items: [],
        },
        {
          level: 1,
          name: "Ministry of Health of the Revolutionary Government of Zanzibar",
          value: 100.81110695334307,
          color: "#013E77",
          items: [],
        },
        {
          level: 1,
          name: "Ministry of Health of the Royal Government of Bhutan",
          value: 107.87663377902541,
          color: "#013E77",
          items: [],
        },
        {
          level: 1,
          name: "Ministry of Health of the State of Eritrea",
          value: 102.87080447943914,
          color: "#013E77",
          items: [],
        },
        {
          level: 1,
          name: "Ministry of Health, Population and Hospital Reform of the People's Democratic Republic of Algeria",
          value: 143.07595235552358,
          color: "#013E77",
          items: [],
        },
        {
          level: 1,
          name: "Ministry of Health, Public Hygiene and Universal Health Coverage of the Republic of Côte d’Ivoire",
          value: 103.72025792086919,
          color: "#013E77",
          items: [],
        },
        {
          level: 1,
          name: "Ministry of Health, Solidarity, Social Protection and Gender Promotion of the Union of the Comoros",
          value: 102.0283207139424,
          color: "#013E77",
          items: [],
        },
        {
          level: 1,
          name: "Ministry of National Health Services, Regulations and Coordination of Pakistan",
          value: 100.84399216760644,
          color: "#013E77",
          items: [],
        },
        {
          level: 1,
          name: "Ministry of Public Health and Fight against AIDS of the Republic of Burundi",
          value: 104.08358738110273,
          color: "#013E77",
          items: [],
        },
        {
          level: 1,
          name: "Ministry of Public Health and Prevention",
          value: 100,
          color: "#013E77",
          items: [],
        },
        {
          level: 1,
          name: "Ministry of Public Health and Social Assistance of the Dominican Republic",
          value: 116.75055996557265,
          color: "#013E77",
          items: [],
        },
        {
          level: 1,
          name: "Ministry of Public Health of the Islamic Republic of Afghanistan",
          value: 100.0387406566066,
          color: "#013E77",
          items: [],
        },
        {
          level: 1,
          name: "Ministry of Public Health of the Republic of Cameroon",
          value: 101.94263245757975,
          color: "#013E77",
          items: [],
        },
        {
          level: 1,
          name: "Ministry of Public Health of the Republic of Ecuador",
          value: 108.8998377066685,
          color: "#013E77",
          items: [],
        },
        {
          level: 1,
          name: "Ministry of Public Health of the Republic of Madagascar",
          value: 105.91927979393014,
          color: "#013E77",
          items: [],
        },
        {
          level: 1,
          name: "Ministry of Public Health, Hygiene and Prevention",
          value: 101.25482664387152,
          color: "#013E77",
          items: [],
        },
        {
          level: 1,
          name: "Ministry of Public Health, Population and Social Affairs",
          value: 100.25416673848817,
          color: "#013E77",
          items: [],
        },
        {
          level: 1,
          name: "Ministère de la Santé Publique et de la Population (Unité de Gestion des Projets)",
          value: 100,
          color: "#013E77",
          items: [],
        },
        {
          level: 1,
          name: "National Agency for the Control of AIDS",
          value: 100.04262227159563,
          color: "#013E77",
          items: [],
        },
        {
          level: 1,
          name: "National Center For Disease Control and Public Health",
          value: 109.06241875251882,
          color: "#013E77",
          items: [],
        },
        {
          level: 1,
          name: "National Department of Health of the Republic of South Africa",
          value: 111.90660416685772,
          color: "#013E77",
          items: [],
        },
        {
          level: 1,
          name: "National Emergency Response Council on HIV and AIDS",
          value: 103.98263311803298,
          color: "#013E77",
          items: [],
        },
        {
          level: 1,
          name: "National Executive Secretariat for fight against Aids of the Islamic Republic of Mauritania",
          value: 105.39136840192566,
          color: "#013E77",
          items: [],
        },
        {
          level: 1,
          name: "National HIV/AIDS Secretariat",
          value: 101.75587051817172,
          color: "#013E77",
          items: [],
        },
        {
          level: 1,
          name: "National High Council for the Fight against AIDS",
          value: 100,
          color: "#013E77",
          items: [],
        },
        {
          level: 1,
          name: "National Malaria Elimination Programme of the Federal Ministry of Health of the Federal Republic of Nigeria",
          value: 100.75584682840643,
          color: "#013E77",
          items: [],
        },
        {
          level: 1,
          name: "National TB Control Programme Pakistan",
          value: 110.22927592289669,
          color: "#013E77",
          items: [],
        },
        {
          level: 1,
          name: "National Treasury of the Republic of Kenya",
          value: 106.0335819183449,
          color: "#013E77",
          items: [],
        },
        {
          level: 1,
          name: "National Tuberculosis & Leprosy Control Programme",
          value: 100.21089596891828,
          color: "#013E77",
          items: [],
        },
        {
          level: 1,
          name: "National Tuberculosis Programme of the Republic of Benin",
          value: 105.7277239973603,
          color: "#013E77",
          items: [],
        },
        {
          level: 1,
          name: "Office National de Nutrition",
          value: 100.6870559511731,
          color: "#013E77",
          items: [],
        },
        {
          level: 1,
          name: "Office National de la Famille et de la Population de la République de Tunisie",
          value: 136.58013825268296,
          color: "#013E77",
          items: [],
        },
        {
          level: 1,
          name: "Primature de la République Togolaise",
          value: 102.22666833836789,
          color: "#013E77",
          items: [],
        },
        {
          level: 1,
          name: "Programme National de Lutte contre la Tuberculose et la Lepre",
          value: 100,
          color: "#013E77",
          items: [],
        },
        {
          level: 1,
          name: "Programme National de Lutte contre le Paludisme de la République du Bénin",
          value: 101.53253023789482,
          color: "#013E77",
          items: [],
        },
        {
          level: 1,
          name: "Programme d'Appui au Développement Sanitaire (PADS)",
          value: 101.21596479064266,
          color: "#013E77",
          items: [],
        },
        {
          level: 1,
          name: "Programme santé de lutte contre le Sida",
          value: 100.74119069036418,
          color: "#013E77",
          items: [],
        },
        {
          level: 1,
          name: "Public Institution - Coordination, Implementation and Monitoring Unit of the Health  System Projects",
          value: 104.35572812795601,
          color: "#013E77",
          items: [],
        },
        {
          level: 1,
          name: "RSE on REU “National Scientific Center of Phthisiopulmonology of the Republic of  Kazakhstan” of the Ministry of Health of the Republic of  Kazakhstan",
          value: 103.70725385221785,
          color: "#013E77",
          items: [],
        },
        {
          level: 1,
          name: "Republican Center of Tuberculosis Control - Tajikistan",
          value: 107.90413829697496,
          color: "#013E77",
          items: [],
        },
        {
          level: 1,
          name: "Republican Center to Fight AIDS",
          value: 103.43806347433284,
          color: "#013E77",
          items: [],
        },
        {
          level: 1,
          name: "Republican DOTS Centre  Ministry of Health of the Republic of Uzbekistan",
          value: 104.78157994347255,
          color: "#013E77",
          items: [],
        },
        {
          level: 1,
          name: "Republican Scientific and Practical Center for Medical Technologies, Informatization, Administration and Management of Health",
          value: 102.72606832879343,
          color: "#013E77",
          items: [],
        },
        {
          level: 1,
          name: "Republican Specialized Scientific-Practical Medical Center of Phthisiology and Pulmonology",
          value: 100,
          color: "#013E77",
          items: [],
        },
        {
          level: 1,
          name: "Secrétariat Permanent du Conseil National de Lutte contre le Sida et les Infections Sexuellement Transmissibles (SP/CNLS-IST)",
          value: 100.95066943997296,
          color: "#013E77",
          items: [],
        },
        {
          level: 1,
          name: "Social Security Board",
          value: 100,
          color: "#013E77",
          items: [],
        },
        {
          level: 1,
          name: "Solomon Islands Ministry of Health and Medical Services",
          value: 100,
          color: "#013E77",
          items: [],
        },
        {
          level: 1,
          name: 'State Institution "Public Health Center of the Ministry of Health of Ukraine"',
          value: 101.73974309818125,
          color: "#013E77",
          items: [],
        },
        {
          level: 1,
          name: "Sécrétariat Exécutif du Comité National de Lutte Contre le VIH/SIDA de la République de Madagascar",
          value: 103.59534291506908,
          color: "#013E77",
          items: [],
        },
        {
          level: 1,
          name: "The Ministry of Health and Child Care of the Republic of Zimbabwe",
          value: 103.64178225593757,
          color: "#013E77",
          items: [],
        },
        {
          level: 1,
          name: "The Ministry of Health of the Republic of Guinea",
          value: 100.1550462474458,
          color: "#013E77",
          items: [],
        },
        {
          level: 1,
          name: "The National AIDS Secretariat of the Republic of The Gambia",
          value: 100.71835467945766,
          color: "#013E77",
          items: [],
        },
        {
          level: 1,
          name: "Viet Nam Authority of HIV/AIDS Control",
          value: 105.10040216077994,
          color: "#013E77",
          items: [],
        },
        {
          level: 1,
          name: "Viet Nam National Lung Hospital",
          value: 104.98451072747308,
          color: "#013E77",
          items: [],
        },
      ],
    },
    {
      level: 0,
      name: "Multilateral Organization",
      value: 105.37548603691766,
      color: "#013E77",
      items: [
        {
          level: 1,
          name: "African Comprehensive HIV/AIDS Partnerships",
          value: 100,
          color: "#013E77",
          items: [],
        },
        {
          level: 1,
          name: "Institute of Nutrition of Central America and Panama (INCAP)",
          value: 102.79504331019808,
          color: "#013E77",
          items: [],
        },
        {
          level: 1,
          name: "Inter-American Development Bank",
          value: 100,
          color: "#013E77",
          items: [],
        },
        {
          level: 1,
          name: "International Bank for Reconstruction and Development",
          value: 100,
          color: "#013E77",
          items: [],
        },
        {
          level: 1,
          name: "International Organization for Migration",
          value: 101.1035175071689,
          color: "#013E77",
          items: [],
        },
        {
          level: 1,
          name: "United Nations Children's Fund",
          value: 100.3356268201649,
          color: "#013E77",
          items: [],
        },
        {
          level: 1,
          name: "United Nations Development Programme",
          value: 106.3203131686814,
          color: "#013E77",
          items: [],
        },
        {
          level: 1,
          name: "United Nations Office for Project Services",
          value: 105.24857452878678,
          color: "#013E77",
          items: [],
        },
      ],
    },
    {
      level: 0,
      name: "Private Sector",
      value: 103.63784120561223,
      color: "#013E77",
      items: [
        {
          level: 1,
          name: "AngloGold Ashanti (Ghana) Malaria Control Limited",
          value: 103.90389510308839,
          color: "#013E77",
          items: [],
        },
        {
          level: 1,
          name: "Indus Hospital & Health Network",
          value: 108.72715150639442,
          color: "#013E77",
          items: [],
        },
        {
          level: 1,
          name: "Pilipinas Shell Foundation, Inc.",
          value: 102.37204984315524,
          color: "#013E77",
          items: [],
        },
        {
          level: 1,
          name: "The Rotary Club of Port Moresby Inc.",
          value: 100.43876843617376,
          color: "#013E77",
          items: [],
        },
        {
          level: 1,
          name: "William J. Clinton Foundation",
          value: 100.36331220343828,
          color: "#013E77",
          items: [],
        },
        {
          level: 1,
          name: "Wits Health Consortium (Pty) Ltd",
          value: 107.13210830202051,
          color: "#013E77",
          items: [],
        },
      ],
    },
  ],
};

export const STORY_DATA_VARIANT_2: FinancialMetricProps = {
  title: "In-Country Absorption",
  legends: [
    {
      name: "Expenditure",
      color: "#00B5AE",
    },
    {
      name: "Budget",
      color: "#CFD4DA",
    },
  ],
  donutChart: {
    id: "in-country-absorption-donut-chart",
    value: 87.88989348244503,
    label: "reported",
    valueColor: "#00B5AE",
  },
  items: [
    {
      level: 0,
      name: "Civil Society Organization",
      value: 91.85698060851759,
      color: "#00B5AE",
      items: [
        {
          level: 1,
          name: "AIDS Foundation South Africa",
          value: 97.3874902331351,
          color: "#00B5AE",
          items: [],
        },
        {
          level: 1,
          name: "ActionAid International Malawi",
          value: 96.29902040064765,
          color: "#00B5AE",
          items: [],
        },
        {
          level: 1,
          name: "ActionAid International The Gambia",
          value: 85.5507001818786,
          color: "#00B5AE",
          items: [],
        },
        {
          level: 1,
          name: "Alliance Nationale pour la Santé et le Développement en Côte D'Ivoire",
          value: 83.44853546515495,
          color: "#00B5AE",
          items: [],
        },
        {
          level: 1,
          name: "Alter Vida - Centro de Estudios y Formación para el Ecodesarrollo",
          value: 98.31129421715009,
          color: "#00B5AE",
          items: [],
        },
        {
          level: 1,
          name: "Amref Health Africa",
          value: 88.28180267551969,
          color: "#00B5AE",
          items: [],
        },
        {
          level: 1,
          name: "Association pour Résilience des Communautés vers l’Accès au Développement et à la Santé Plus",
          value: 77.18925828417007,
          color: "#00B5AE",
          items: [],
        },
        {
          level: 1,
          name: "BRAC",
          value: 88.99564386189259,
          color: "#00B5AE",
          items: [],
        },
        {
          level: 1,
          name: "Beyond Zero NPC",
          value: 93.39180422742851,
          color: "#00B5AE",
          items: [],
        },
        {
          level: 1,
          name: "CARE INTERNATIONAL CAMEROON",
          value: 30.17523082716555,
          color: "#00B5AE",
          items: [],
        },
        {
          level: 1,
          name: "CARE Peru",
          value: 101.33872803269723,
          color: "#00B5AE",
          items: [],
        },
        {
          level: 1,
          name: "Cameroon National Association for Family Welfare",
          value: 99.083891273295,
          color: "#00B5AE",
          items: [],
        },
        {
          level: 1,
          name: "Caribbean Community (CARICOM)",
          value: 90.84524451957363,
          color: "#00B5AE",
          items: [],
        },
        {
          level: 1,
          name: "Catholic Relief Services - United States Conference of Catholic Bishops",
          value: 95.19789915172441,
          color: "#00B5AE",
          items: [],
        },
        {
          level: 1,
          name: "Center for Health Policies and Studies",
          value: 100.10780030484614,
          color: "#00B5AE",
          items: [],
        },
        {
          level: 1,
          name: "Central Board of ‘Aisyiyah",
          value: 104.71694430714665,
          color: "#00B5AE",
          items: [],
        },
        {
          level: 1,
          name: "Centre de Recherches Médicales de Lambaréné",
          value: 89.82837092716025,
          color: "#00B5AE",
          items: [],
        },
        {
          level: 1,
          name: "Centre for Health Research and Innovation",
          value: 96.89163395365762,
          color: "#00B5AE",
          items: [],
        },
        {
          level: 1,
          name: "Centro de Colaboração em Saúde",
          value: 96.9008869836308,
          color: "#00B5AE",
          items: [],
        },
        {
          level: 1,
          name: "Centro de Información y Recursos para el Desarrollo",
          value: 85.9945218918184,
          color: "#00B5AE",
          items: [],
        },
        {
          level: 1,
          name: 'Charitable Organization "All-Ukrainian Network of People Living with HIV/AIDS"',
          value: 95.42325114701974,
          color: "#00B5AE",
          items: [],
        },
        {
          level: 1,
          name: "Christian Health Association of Ghana",
          value: 95.52922448841042,
          color: "#00B5AE",
          items: [],
        },
        {
          level: 1,
          name: "Churches Health Association of Zambia",
          value: 94.82560805542481,
          color: "#00B5AE",
          items: [],
        },
        {
          level: 1,
          name: "Community Development Fund",
          value: 96.2820264318884,
          color: "#00B5AE",
          items: [],
        },
        {
          level: 1,
          name: "Cooperative Housing Foundation",
          value: 78.23871299021813,
          color: "#00B5AE",
          items: [],
        },
        {
          level: 1,
          name: "Coordinating Assembly of Non Governmental Organisation",
          value: 88.95557650783896,
          color: "#00B5AE",
          items: [],
        },
        {
          level: 1,
          name: "East, Central and Southern Africa Health Community",
          value: 75.42575775678763,
          color: "#00B5AE",
          items: [],
        },
        {
          level: 1,
          name: "Family Health International (FHI360)",
          value: 83.85294860228866,
          color: "#00B5AE",
          items: [],
        },
        {
          level: 1,
          name: "Federación Red NICASALUD",
          value: 96.95586973683437,
          color: "#00B5AE",
          items: [],
        },
        {
          level: 1,
          name: "Foundation for Innovative New Diagnostics India",
          value: 79.733364912027,
          color: "#00B5AE",
          items: [],
        },
        {
          level: 1,
          name: "Frontline AIDS",
          value: 88.76116618865699,
          color: "#00B5AE",
          items: [],
        },
        {
          level: 1,
          name: "Fundação para o Desenvolvimento da Comunidade",
          value: 78.38124251580003,
          color: "#00B5AE",
          items: [],
        },
        {
          level: 1,
          name: "Ghana-West Africa Program to Combat AIDS - STI",
          value: 99.38660455360564,
          color: "#00B5AE",
          items: [],
        },
        {
          level: 1,
          name: "Humanist Institute for Co-operation with Developing Countries",
          value: 101.29417282521229,
          color: "#00B5AE",
          items: [],
        },
        {
          level: 1,
          name: "Humanist Institute for Co-operation with Developing Countries (Hivos)",
          value: 95.41200976769322,
          color: "#00B5AE",
          items: [],
        },
        {
          level: 1,
          name: "India HIV/AIDS Alliance",
          value: 88.32626415461839,
          color: "#00B5AE",
          items: [],
        },
        {
          level: 1,
          name: "Initiative Privée et Communautaire pour la Santé et la Riposte au VIH/SIDA  au Burkina Faso (IPC/BF)",
          value: 99.42704283936949,
          color: "#00B5AE",
          items: [],
        },
        {
          level: 1,
          name: "Institute of Human Virology Nigeria",
          value: 97.15400899867156,
          color: "#00B5AE",
          items: [],
        },
        {
          level: 1,
          name: "Instituto Dermatológico y Cirugía de Piel ‘Dr. Huberto Bogaert Díaz’",
          value: 101.58513124555793,
          color: "#00B5AE",
          items: [],
        },
        {
          level: 1,
          name: "International Centre for Diarrhoeal Disease Research, Bangladesh",
          value: 90.46164007151467,
          color: "#00B5AE",
          items: [],
        },
        {
          level: 1,
          name: "International Charitable Foundation “Alliance for Public Health”",
          value: 99.63588062323254,
          color: "#00B5AE",
          items: [],
        },
        {
          level: 1,
          name: "International Union Against Tuberculosis and Lung Disease",
          value: 86.89006309844636,
          color: "#00B5AE",
          items: [],
        },
        {
          level: 1,
          name: "Kenya Red Cross Society",
          value: 93.11436117422792,
          color: "#00B5AE",
          items: [],
        },
        {
          level: 1,
          name: "Konsorsium Komunitas PENABULU-STPI",
          value: 96.66103264169394,
          color: "#00B5AE",
          items: [],
        },
        {
          level: 1,
          name: "La Croix-Rouge française",
          value: 89.56942463959311,
          color: "#00B5AE",
          items: [],
        },
        {
          level: 1,
          name: "Management Sciences for Health",
          value: 99.9595415772362,
          color: "#00B5AE",
          items: [],
        },
        {
          level: 1,
          name: "Mercy Corps",
          value: 98.54767265982582,
          color: "#00B5AE",
          items: [],
        },
        {
          level: 1,
          name: "Nai Zindagi",
          value: 95.79238252401487,
          color: "#00B5AE",
          items: [],
        },
        {
          level: 1,
          name: "Namibia Network of AIDS Service Organizations",
          value: 96.63681249484667,
          color: "#00B5AE",
          items: [],
        },
        {
          level: 1,
          name: "Networking HIV and AIDS Community of Southern Africa NPC",
          value: 92.63468570711156,
          color: "#00B5AE",
          items: [],
        },
        {
          level: 1,
          name: "Organisation of Eastern Caribbean States",
          value: 85.64759474475207,
          color: "#00B5AE",
          items: [],
        },
        {
          level: 1,
          name: "Organismo Andino de Salud - Convenio Hipólito Unanue",
          value: 87.40359883647801,
          color: "#00B5AE",
          items: [],
        },
        {
          level: 1,
          name: "Pact, Inc.",
          value: 106.77373296103838,
          color: "#00B5AE",
          items: [],
        },
        {
          level: 1,
          name: "Partners In Health",
          value: 99.14287410878957,
          color: "#00B5AE",
          items: [],
        },
        {
          level: 1,
          name: "Persatuan Karya Dharma Kesehatan Indonesia (also known as “PERDHAKI”, Association of Voluntary Health Services of Indonesia)",
          value: 75.8805745848549,
          color: "#00B5AE",
          items: [],
        },
        {
          level: 1,
          name: "Philippine Business for Social Progress, Inc.",
          value: 95.63671338799881,
          color: "#00B5AE",
          items: [],
        },
        {
          level: 1,
          name: "Plan International (India Chapter)",
          value: 75.96399954350646,
          color: "#00B5AE",
          items: [],
        },
        {
          level: 1,
          name: "Plan International El Salvador",
          value: 76.73306462010004,
          color: "#00B5AE",
          items: [],
        },
        {
          level: 1,
          name: "Plan International, Inc.",
          value: 91.43887010060563,
          color: "#00B5AE",
          items: [],
        },
        {
          level: 1,
          name: "Population Services International",
          value: 99.86616416385964,
          color: "#00B5AE",
          items: [],
        },
        {
          level: 1,
          name: "Prévention Information Lutte contre le Sida",
          value: 105.9814452804319,
          color: "#00B5AE",
          items: [],
        },
        {
          level: 1,
          name: "Raks Thai Foundation",
          value: 78.12999419863944,
          color: "#00B5AE",
          items: [],
        },
        {
          level: 1,
          name: "SADC Malaria Elimination Eight Secretariat",
          value: 98.64750701260525,
          color: "#00B5AE",
          items: [],
        },
        {
          level: 1,
          name: "SANRU Asbl",
          value: 95.09735786330491,
          color: "#00B5AE",
          items: [],
        },
        {
          level: 1,
          name: "Save the Children Federation, Inc.",
          value: 95.16636113797344,
          color: "#00B5AE",
          items: [],
        },
        {
          level: 1,
          name: "Society For Family Health",
          value: 100.89435359715577,
          color: "#00B5AE",
          items: [],
        },
        {
          level: 1,
          name: "Socios en Salud sucursal Peru",
          value: 74.46846560279316,
          color: "#00B5AE",
          items: [],
        },
        {
          level: 1,
          name: "Solidarity and Action Against The HIV Infection in India",
          value: 78.65034673699688,
          color: "#00B5AE",
          items: [],
        },
        {
          level: 1,
          name: "St. Petersburg charitable fund programs “Humanitarian action”",
          value: 70.26968760536182,
          color: "#00B5AE",
          items: [],
        },
        {
          level: 1,
          name: "Stichting Cordaid",
          value: 94.2321671745416,
          color: "#00B5AE",
          items: [],
        },
        {
          level: 1,
          name: "T.C.I. Foundation",
          value: 53.22796461021107,
          color: "#00B5AE",
          items: [],
        },
        {
          level: 1,
          name: "The AIDS Support Organisation (Uganda) Limited",
          value: 77.6155721345757,
          color: "#00B5AE",
          items: [],
        },
        {
          level: 1,
          name: "The Family Planning Association of Sri Lanka",
          value: 103.4086473776598,
          color: "#00B5AE",
          items: [],
        },
        {
          level: 1,
          name: "Viet Nam Union of Science and Technology Associations",
          value: 102.05285526982671,
          color: "#00B5AE",
          items: [],
        },
        {
          level: 1,
          name: "World Vision (PNG) Trust",
          value: 90.56657453362547,
          color: "#00B5AE",
          items: [],
        },
        {
          level: 1,
          name: "World Vision International",
          value: 82.86646045695335,
          color: "#00B5AE",
          items: [],
        },
        {
          level: 1,
          name: "World Vision Malawi",
          value: 99.42771970516762,
          color: "#00B5AE",
          items: [],
        },
        {
          level: 1,
          name: "Yayasan Spiritia",
          value: 80.73598172843907,
          color: "#00B5AE",
          items: [],
        },
      ],
    },
    {
      level: 0,
      name: "Governmental Organization",
      value: 85.76188986627878,
      color: "#00B5AE",
      items: [
        {
          level: 1,
          name: "Alliance Nationale des Communautés pour la Santé - Sénégal",
          value: 100.92235039396851,
          color: "#00B5AE",
          items: [],
        },
        {
          level: 1,
          name: "Australian Federation Of AIDS Organisations Limited",
          value: 98.1033622731952,
          color: "#00B5AE",
          items: [],
        },
        {
          level: 1,
          name: "Botswana-Ministry of Health",
          value: 60.85238684805741,
          color: "#00B5AE",
          items: [],
        },
        {
          level: 1,
          name: "Cambodia Ministry of Economy and Finance",
          value: 81.46673548297635,
          color: "#00B5AE",
          items: [],
        },
        {
          level: 1,
          name: "Cellule Nationale de Coordination Technique de la Riposte Nationale au Sida et aux Hépatites",
          value: 100.49295798595432,
          color: "#00B5AE",
          items: [],
        },
        {
          level: 1,
          name: "Conseil National de Lutte contre le SIDA de la République du Sénégal",
          value: 85.3061557678377,
          color: "#00B5AE",
          items: [],
        },
        {
          level: 1,
          name: "Conseil National de Lutte contre le VIH/SIDA, la Tuberculose, le Paludisme, les Hépatites, les Infections sexuellement transmissibles et les Epidémies",
          value: 55.32450287022682,
          color: "#00B5AE",
          items: [],
        },
        {
          level: 1,
          name: "Consejo Nacional para el VIH y el SIDA",
          value: 88.62308615196677,
          color: "#00B5AE",
          items: [],
        },
        {
          level: 1,
          name: "Coordination Committee of the Fight against AIDS of Cabo Verde",
          value: 77.93487908311216,
          color: "#00B5AE",
          items: [],
        },
        {
          level: 1,
          name: "Department of Disease Control, Ministry of Public Health of the Royal Government of Thailand",
          value: 94.75314789966811,
          color: "#00B5AE",
          items: [],
        },
        {
          level: 1,
          name: "Department of Economic Affairs, Ministry of Finance of India",
          value: 76.38705217048405,
          color: "#00B5AE",
          items: [],
        },
        {
          level: 1,
          name: "Directorate General of Disease Prevention and Control, Ministry of Health of The Republic of Indonesia",
          value: 85.81323691610525,
          color: "#00B5AE",
          items: [],
        },
        {
          level: 1,
          name: "Directorate of Malaria Control, Ministry of National Health Services, Regulations and Coordination of Pakistan",
          value: 100.30429006020134,
          color: "#00B5AE",
          items: [],
        },
        {
          level: 1,
          name: "Economic Relations Division, Ministry of Finance of the People's Republic of Bangladesh",
          value: 66.44622824997731,
          color: "#00B5AE",
          items: [],
        },
        {
          level: 1,
          name: "Empresa Nacional Promotora del Desarrollo Territorial",
          value: 83.8486493111898,
          color: "#00B5AE",
          items: [],
        },
        {
          level: 1,
          name: "Federal Ministry of Health of the Federal Democratic Republic of Ethiopia",
          value: 90.6515468313537,
          color: "#00B5AE",
          items: [],
        },
        {
          level: 1,
          name: "Federal Ministry of Health of the Republic of Sudan",
          value: 89.71187464570576,
          color: "#00B5AE",
          items: [],
        },
        {
          level: 1,
          name: "HIV/AIDS Prevention and Control Office",
          value: 80.65678562579191,
          color: "#00B5AE",
          items: [],
        },
        {
          level: 1,
          name: "Indonesia AIDS Coalition",
          value: 62.919179015263715,
          color: "#00B5AE",
          items: [],
        },
        {
          level: 1,
          name: "Instituto Nicaragüense de Seguridad Social",
          value: 142.3038269650457,
          color: "#00B5AE",
          items: [],
        },
        {
          level: 1,
          name: "Intergovernmental Authority on Development",
          value: 98.3100948988293,
          color: "#00B5AE",
          items: [],
        },
        {
          level: 1,
          name: "Kazakh scientific center of dermatology and infectious diseases of the Ministry of Health of the Republic of Kazakhstan",
          value: 101.81286786117214,
          color: "#00B5AE",
          items: [],
        },
        {
          level: 1,
          name: "Lagos State Ministry of Health",
          value: 105.08627643179591,
          color: "#00B5AE",
          items: [],
        },
        {
          level: 1,
          name: "Lubombo Spatial Development Initiative 2 NPC",
          value: 92.78929279218553,
          color: "#00B5AE",
          items: [],
        },
        {
          level: 1,
          name: "Malaysian AIDS Council",
          value: 81.45338557212331,
          color: "#00B5AE",
          items: [],
        },
        {
          level: 1,
          name: "Ministry of Finance of the Kingdom of Lesotho",
          value: 87.21646833438442,
          color: "#00B5AE",
          items: [],
        },
        {
          level: 1,
          name: "Ministry of Finance of the United Republic of Tanzania",
          value: 81.70216922242767,
          color: "#00B5AE",
          items: [],
        },
        {
          level: 1,
          name: "Ministry of Finance, Planning and Economic Development of the Republic of Uganda",
          value: 71.64719591415889,
          color: "#00B5AE",
          items: [],
        },
        {
          level: 1,
          name: "Ministry of Health",
          value: 91.29091163479063,
          color: "#00B5AE",
          items: [],
        },
        {
          level: 1,
          name: "Ministry of Health and Social Action of the Republic of Senegal",
          value: 81.75649746225993,
          color: "#00B5AE",
          items: [],
        },
        {
          level: 1,
          name: "Ministry of Health and Social Assistance of the Republic of Guatemala",
          value: 98.09299346822489,
          color: "#00B5AE",
          items: [],
        },
        {
          level: 1,
          name: "Ministry of Health and Social Development of the Republic of Mali",
          value: 47.108581691093825,
          color: "#00B5AE",
          items: [],
        },
        {
          level: 1,
          name: "Ministry of Health and Social Protection of the Republic of Albania",
          value: 110.96600776648506,
          color: "#00B5AE",
          items: [],
        },
        {
          level: 1,
          name: "Ministry of Health and Social Services of Namibia",
          value: 89.91639890826903,
          color: "#00B5AE",
          items: [],
        },
        {
          level: 1,
          name: "Ministry of Health and Social Welfare",
          value: 82.65414852556849,
          color: "#00B5AE",
          items: [],
        },
        {
          level: 1,
          name: "Ministry of Health and Wellness",
          value: 61.16131447401955,
          color: "#00B5AE",
          items: [],
        },
        {
          level: 1,
          name: "Ministry of Health and Wellness,  Jamaica",
          value: 97.63237279061472,
          color: "#00B5AE",
          items: [],
        },
        {
          level: 1,
          name: "Ministry of Health of Mongolia",
          value: 91.58372608964956,
          color: "#00B5AE",
          items: [],
        },
        {
          level: 1,
          name: "Ministry of Health of Montenegro",
          value: 81.36149266731636,
          color: "#00B5AE",
          items: [],
        },
        {
          level: 1,
          name: "Ministry of Health of Mozambique",
          value: 84.70410926090535,
          color: "#00B5AE",
          items: [],
        },
        {
          level: 1,
          name: "Ministry of Health of Republic of Serbia",
          value: 90.07173955300006,
          color: "#00B5AE",
          items: [],
        },
        {
          level: 1,
          name: "Ministry of Health of Romania",
          value: 70.13133885461426,
          color: "#00B5AE",
          items: [],
        },
        {
          level: 1,
          name: "Ministry of Health of Sao Tome and Principe",
          value: 88.46865417399526,
          color: "#00B5AE",
          items: [],
        },
        {
          level: 1,
          name: "Ministry of Health of the Co-operative Republic of Guyana",
          value: 91.00486215855969,
          color: "#00B5AE",
          items: [],
        },
        {
          level: 1,
          name: "Ministry of Health of the Democratic Republic of Timor-Leste",
          value: 97.6477818884732,
          color: "#00B5AE",
          items: [],
        },
        {
          level: 1,
          name: "Ministry of Health of the Democratic Socialist  Republic of Sri Lanka",
          value: 77.21093248567722,
          color: "#00B5AE",
          items: [],
        },
        {
          level: 1,
          name: "Ministry of Health of the Kingdom of Morocco",
          value: 96.58242994559535,
          color: "#00B5AE",
          items: [],
        },
        {
          level: 1,
          name: "Ministry of Health of the Lao People's Democratic Republic",
          value: 97.4339690265088,
          color: "#00B5AE",
          items: [],
        },
        {
          level: 1,
          name: "Ministry of Health of the Republic of Angola",
          value: 101.26476139428019,
          color: "#00B5AE",
          items: [],
        },
        {
          level: 1,
          name: "Ministry of Health of the Republic of Armenia",
          value: 94.52695305637498,
          color: "#00B5AE",
          items: [],
        },
        {
          level: 1,
          name: "Ministry of Health of the Republic of Azerbaijan",
          value: 93.58375420729612,
          color: "#00B5AE",
          items: [],
        },
        {
          level: 1,
          name: "Ministry of Health of the Republic of El Salvador",
          value: 62.49240965243551,
          color: "#00B5AE",
          items: [],
        },
        {
          level: 1,
          name: "Ministry of Health of the Republic of Ghana",
          value: 100.28590085850344,
          color: "#00B5AE",
          items: [],
        },
        {
          level: 1,
          name: "Ministry of Health of the Republic of Guinea-Bissau",
          value: 77.9410431750981,
          color: "#00B5AE",
          items: [],
        },
        {
          level: 1,
          name: "Ministry of Health of the Republic of Malawi",
          value: 98.08863402837714,
          color: "#00B5AE",
          items: [],
        },
        {
          level: 1,
          name: "Ministry of Health of the Republic of Rwanda",
          value: 91.96043249643331,
          color: "#00B5AE",
          items: [],
        },
        {
          level: 1,
          name: "Ministry of Health of the Republic of Suriname",
          value: 72.11778355902794,
          color: "#00B5AE",
          items: [],
        },
        {
          level: 1,
          name: "Ministry of Health of the Republic of The Gambia",
          value: 98.50466766841114,
          color: "#00B5AE",
          items: [],
        },
        {
          level: 1,
          name: "Ministry of Health of the Republic of Zambia",
          value: 85.54924684603846,
          color: "#00B5AE",
          items: [],
        },
        {
          level: 1,
          name: "Ministry of Health of the Revolutionary Government of Zanzibar",
          value: 85.63901357240283,
          color: "#00B5AE",
          items: [],
        },
        {
          level: 1,
          name: "Ministry of Health of the Royal Government of Bhutan",
          value: 102.35281532792864,
          color: "#00B5AE",
          items: [],
        },
        {
          level: 1,
          name: "Ministry of Health of the State of Eritrea",
          value: 77.36805642258317,
          color: "#00B5AE",
          items: [],
        },
        {
          level: 1,
          name: "Ministry of Health, Population and Hospital Reform of the People's Democratic Republic of Algeria",
          value: 134.57394168666843,
          color: "#00B5AE",
          items: [],
        },
        {
          level: 1,
          name: "Ministry of Health, Public Hygiene and Universal Health Coverage of the Republic of Côte d’Ivoire",
          value: 88.04390474170562,
          color: "#00B5AE",
          items: [],
        },
        {
          level: 1,
          name: "Ministry of Health, Solidarity, Social Protection and Gender Promotion of the Union of the Comoros",
          value: 89.75632218420988,
          color: "#00B5AE",
          items: [],
        },
        {
          level: 1,
          name: "Ministry of National Health Services, Regulations and Coordination of Pakistan",
          value: 100.17823503570058,
          color: "#00B5AE",
          items: [],
        },
        {
          level: 1,
          name: "Ministry of Public Health and Fight against AIDS of the Republic of Burundi",
          value: 90.3970374542882,
          color: "#00B5AE",
          items: [],
        },
        {
          level: 1,
          name: "Ministry of Public Health and Prevention",
          value: 92.87542682309375,
          color: "#00B5AE",
          items: [],
        },
        {
          level: 1,
          name: "Ministry of Public Health and Social Assistance of the Dominican Republic",
          value: 114.97550631471928,
          color: "#00B5AE",
          items: [],
        },
        {
          level: 1,
          name: "Ministry of Public Health of the Islamic Republic of Afghanistan",
          value: 94.51531797127008,
          color: "#00B5AE",
          items: [],
        },
        {
          level: 1,
          name: "Ministry of Public Health of the Republic of Cameroon",
          value: 87.15058621746589,
          color: "#00B5AE",
          items: [],
        },
        {
          level: 1,
          name: "Ministry of Public Health of the Republic of Ecuador",
          value: 97.48209851935155,
          color: "#00B5AE",
          items: [],
        },
        {
          level: 1,
          name: "Ministry of Public Health of the Republic of Madagascar",
          value: 81.11096975583145,
          color: "#00B5AE",
          items: [],
        },
        {
          level: 1,
          name: "Ministry of Public Health, Hygiene and Prevention",
          value: 80.46337972835535,
          color: "#00B5AE",
          items: [],
        },
        {
          level: 1,
          name: "Ministry of Public Health, Population and Social Affairs",
          value: 67.35436066258077,
          color: "#00B5AE",
          items: [],
        },
        {
          level: 1,
          name: "Ministère de la Santé Publique et de la Population (Unité de Gestion des Projets)",
          value: 52.89474011835345,
          color: "#00B5AE",
          items: [],
        },
        {
          level: 1,
          name: "National Agency for the Control of AIDS",
          value: 61.46397909217322,
          color: "#00B5AE",
          items: [],
        },
        {
          level: 1,
          name: "National Center For Disease Control and Public Health",
          value: 98.82536155552847,
          color: "#00B5AE",
          items: [],
        },
        {
          level: 1,
          name: "National Department of Health of the Republic of South Africa",
          value: 100.84848603374448,
          color: "#00B5AE",
          items: [],
        },
        {
          level: 1,
          name: "National Emergency Response Council on HIV and AIDS",
          value: 93.56834672254337,
          color: "#00B5AE",
          items: [],
        },
        {
          level: 1,
          name: "National Executive Secretariat for fight against Aids of the Islamic Republic of Mauritania",
          value: 88.15173826938792,
          color: "#00B5AE",
          items: [],
        },
        {
          level: 1,
          name: "National HIV/AIDS Secretariat",
          value: 100.71653192817651,
          color: "#00B5AE",
          items: [],
        },
        {
          level: 1,
          name: "National High Council for the Fight against AIDS",
          value: 100.22078290229477,
          color: "#00B5AE",
          items: [],
        },
        {
          level: 1,
          name: "National Malaria Elimination Programme of the Federal Ministry of Health of the Federal Republic of Nigeria",
          value: 101.87048587917738,
          color: "#00B5AE",
          items: [],
        },
        {
          level: 1,
          name: "National TB Control Programme Pakistan",
          value: 71.48293920941889,
          color: "#00B5AE",
          items: [],
        },
        {
          level: 1,
          name: "National Treasury of the Republic of Kenya",
          value: 92.29395748771161,
          color: "#00B5AE",
          items: [],
        },
        {
          level: 1,
          name: "National Tuberculosis & Leprosy Control Programme",
          value: 72.01752376092962,
          color: "#00B5AE",
          items: [],
        },
        {
          level: 1,
          name: "National Tuberculosis Programme of the Republic of Benin",
          value: 88.6858578533845,
          color: "#00B5AE",
          items: [],
        },
        {
          level: 1,
          name: "Office National de Nutrition",
          value: 101.18805079394917,
          color: "#00B5AE",
          items: [],
        },
        {
          level: 1,
          name: "Office National de la Famille et de la Population de la République de Tunisie",
          value: 104.20194586141542,
          color: "#00B5AE",
          items: [],
        },
        {
          level: 1,
          name: "Primature de la République Togolaise",
          value: 95.71778260031162,
          color: "#00B5AE",
          items: [],
        },
        {
          level: 1,
          name: "Programme National de Lutte contre la Tuberculose et la Lepre",
          value: 99.99944890802642,
          color: "#00B5AE",
          items: [],
        },
        {
          level: 1,
          name: "Programme National de Lutte contre le Paludisme de la République du Bénin",
          value: 101.10439010337828,
          color: "#00B5AE",
          items: [],
        },
        {
          level: 1,
          name: "Programme d'Appui au Développement Sanitaire (PADS)",
          value: 90.72086611361098,
          color: "#00B5AE",
          items: [],
        },
        {
          level: 1,
          name: "Programme santé de lutte contre le Sida",
          value: 93.43547788374958,
          color: "#00B5AE",
          items: [],
        },
        {
          level: 1,
          name: "Public Institution - Coordination, Implementation and Monitoring Unit of the Health  System Projects",
          value: 101.56071914662692,
          color: "#00B5AE",
          items: [],
        },
        {
          level: 1,
          name: "RSE on REU “National Scientific Center of Phthisiopulmonology of the Republic of  Kazakhstan” of the Ministry of Health of the Republic of  Kazakhstan",
          value: 96.03827076347736,
          color: "#00B5AE",
          items: [],
        },
        {
          level: 1,
          name: "Republican Center of Tuberculosis Control - Tajikistan",
          value: 107.80755745916481,
          color: "#00B5AE",
          items: [],
        },
        {
          level: 1,
          name: "Republican Center to Fight AIDS",
          value: 87.06712511485502,
          color: "#00B5AE",
          items: [],
        },
        {
          level: 1,
          name: "Republican DOTS Centre  Ministry of Health of the Republic of Uzbekistan",
          value: 103.80879075598632,
          color: "#00B5AE",
          items: [],
        },
        {
          level: 1,
          name: "Republican Scientific and Practical Center for Medical Technologies, Informatization, Administration and Management of Health",
          value: 87.88656667283254,
          color: "#00B5AE",
          items: [],
        },
        {
          level: 1,
          name: "Republican Specialized Scientific-Practical Medical Center of Phthisiology and Pulmonology",
          value: 99.77603518882098,
          color: "#00B5AE",
          items: [],
        },
        {
          level: 1,
          name: "Secrétariat Permanent du Conseil National de Lutte contre le Sida et les Infections Sexuellement Transmissibles (SP/CNLS-IST)",
          value: 94.91092607818102,
          color: "#00B5AE",
          items: [],
        },
        {
          level: 1,
          name: "Social Security Board",
          value: 83.35457895245943,
          color: "#00B5AE",
          items: [],
        },
        {
          level: 1,
          name: "Solomon Islands Ministry of Health and Medical Services",
          value: 59.72665870663085,
          color: "#00B5AE",
          items: [],
        },
        {
          level: 1,
          name: 'State Institution "Public Health Center of the Ministry of Health of Ukraine"',
          value: 85.53351055226472,
          color: "#00B5AE",
          items: [],
        },
        {
          level: 1,
          name: "Sécrétariat Exécutif du Comité National de Lutte Contre le VIH/SIDA de la République de Madagascar",
          value: 102.90358219070448,
          color: "#00B5AE",
          items: [],
        },
        {
          level: 1,
          name: "The Ministry of Health and Child Care of the Republic of Zimbabwe",
          value: 87.42559792536626,
          color: "#00B5AE",
          items: [],
        },
        {
          level: 1,
          name: "The Ministry of Health of the Republic of Guinea",
          value: 98.17585141392922,
          color: "#00B5AE",
          items: [],
        },
        {
          level: 1,
          name: "The National AIDS Secretariat of the Republic of The Gambia",
          value: 101.72283647949052,
          color: "#00B5AE",
          items: [],
        },
        {
          level: 1,
          name: "Viet Nam Authority of HIV/AIDS Control",
          value: 99.77533866220159,
          color: "#00B5AE",
          items: [],
        },
        {
          level: 1,
          name: "Viet Nam National Lung Hospital",
          value: 95.00874878040942,
          color: "#00B5AE",
          items: [],
        },
      ],
    },
    {
      level: 0,
      name: "Multilateral Organization",
      value: 88.3375121527742,
      color: "#00B5AE",
      items: [
        {
          level: 1,
          name: "African Comprehensive HIV/AIDS Partnerships",
          value: 97.6034366823948,
          color: "#00B5AE",
          items: [],
        },
        {
          level: 1,
          name: "Institute of Nutrition of Central America and Panama (INCAP)",
          value: 93.09980161239493,
          color: "#00B5AE",
          items: [],
        },
        {
          level: 1,
          name: "Inter-American Development Bank",
          value: 2.0515308854102465,
          color: "#00B5AE",
          items: [],
        },
        {
          level: 1,
          name: "International Bank for Reconstruction and Development",
          value: 100,
          color: "#00B5AE",
          items: [],
        },
        {
          level: 1,
          name: "International Organization for Migration",
          value: 90.0680610285576,
          color: "#00B5AE",
          items: [],
        },
        {
          level: 1,
          name: "United Nations Children's Fund",
          value: 92.80995932435665,
          color: "#00B5AE",
          items: [],
        },
        {
          level: 1,
          name: "United Nations Development Programme",
          value: 89.28458501005485,
          color: "#00B5AE",
          items: [],
        },
        {
          level: 1,
          name: "United Nations Office for Project Services",
          value: 84.65204206582902,
          color: "#00B5AE",
          items: [],
        },
      ],
    },
    {
      level: 0,
      name: "Private Sector",
      value: 93.11676013384232,
      color: "#00B5AE",
      items: [
        {
          level: 1,
          name: "AngloGold Ashanti (Ghana) Malaria Control Limited",
          value: 101.2451328258146,
          color: "#00B5AE",
          items: [],
        },
        {
          level: 1,
          name: "Indus Hospital & Health Network",
          value: 83.94441008252653,
          color: "#00B5AE",
          items: [],
        },
        {
          level: 1,
          name: "Pilipinas Shell Foundation, Inc.",
          value: 97.25553724081402,
          color: "#00B5AE",
          items: [],
        },
        {
          level: 1,
          name: "The Rotary Club of Port Moresby Inc.",
          value: 98.57423254834339,
          color: "#00B5AE",
          items: [],
        },
        {
          level: 1,
          name: "William J. Clinton Foundation",
          value: 77.34397371273892,
          color: "#00B5AE",
          items: [],
        },
        {
          level: 1,
          name: "Wits Health Consortium (Pty) Ltd",
          value: 110.76280220549614,
          color: "#00B5AE",
          items: [],
        },
      ],
    },
  ],
};

export const STORY_DATA_VARIANT_3: FinancialMetricProps = {
  title: "Disbursement Utilisation",
  legends: [
    {
      name: "Expenditure",
      color: "#0A2840",
    },
    {
      name: "Disbursement",
      color: "#CFD4DA",
    },
  ],
  donutChart: {
    id: "disbursement-utilisation-donut-chart",
    value: 87.88989348244503,
    label: "utilised",
    valueColor: "#0A2840",
  },
  items: [
    {
      level: 0,
      name: "Civil Society Organization",
      value: 91.85698060851757,
      color: "#0A2840",
      items: [
        {
          level: 1,
          name: "AIDS Foundation South Africa",
          value: 97.3874902331351,
          color: "#0A2840",
          items: [],
        },
        {
          level: 1,
          name: "ActionAid International Malawi",
          value: 96.29902040064765,
          color: "#0A2840",
          items: [],
        },
        {
          level: 1,
          name: "ActionAid International The Gambia",
          value: 85.5507001818786,
          color: "#0A2840",
          items: [],
        },
        {
          level: 1,
          name: "Alliance Nationale pour la Santé et le Développement en Côte D'Ivoire",
          value: 83.44853546515495,
          color: "#0A2840",
          items: [],
        },
        {
          level: 1,
          name: "Alter Vida - Centro de Estudios y Formación para el Ecodesarrollo",
          value: 98.31129421715009,
          color: "#0A2840",
          items: [],
        },
        {
          level: 1,
          name: "Amref Health Africa",
          value: 88.28180267551969,
          color: "#0A2840",
          items: [],
        },
        {
          level: 1,
          name: "Association pour Résilience des Communautés vers l’Accès au Développement et à la Santé Plus",
          value: 77.18925828417007,
          color: "#0A2840",
          items: [],
        },
        {
          level: 1,
          name: "BRAC",
          value: 88.99564386189259,
          color: "#0A2840",
          items: [],
        },
        {
          level: 1,
          name: "Beyond Zero NPC",
          value: 93.39180422742851,
          color: "#0A2840",
          items: [],
        },
        {
          level: 1,
          name: "CARE INTERNATIONAL CAMEROON",
          value: 30.17523082716555,
          color: "#0A2840",
          items: [],
        },
        {
          level: 1,
          name: "CARE Peru",
          value: 101.33872803269723,
          color: "#0A2840",
          items: [],
        },
        {
          level: 1,
          name: "Cameroon National Association for Family Welfare",
          value: 99.083891273295,
          color: "#0A2840",
          items: [],
        },
        {
          level: 1,
          name: "Caribbean Community (CARICOM)",
          value: 90.84524451957363,
          color: "#0A2840",
          items: [],
        },
        {
          level: 1,
          name: "Catholic Relief Services - United States Conference of Catholic Bishops",
          value: 95.19789915172441,
          color: "#0A2840",
          items: [],
        },
        {
          level: 1,
          name: "Center for Health Policies and Studies",
          value: 100.10780030484614,
          color: "#0A2840",
          items: [],
        },
        {
          level: 1,
          name: "Central Board of ‘Aisyiyah",
          value: 104.71694430714665,
          color: "#0A2840",
          items: [],
        },
        {
          level: 1,
          name: "Centre de Recherches Médicales de Lambaréné",
          value: 89.82837092716025,
          color: "#0A2840",
          items: [],
        },
        {
          level: 1,
          name: "Centre for Health Research and Innovation",
          value: 96.89163395365762,
          color: "#0A2840",
          items: [],
        },
        {
          level: 1,
          name: "Centro de Colaboração em Saúde",
          value: 96.9008869836308,
          color: "#0A2840",
          items: [],
        },
        {
          level: 1,
          name: "Centro de Información y Recursos para el Desarrollo",
          value: 85.9945218918184,
          color: "#0A2840",
          items: [],
        },
        {
          level: 1,
          name: 'Charitable Organization "All-Ukrainian Network of People Living with HIV/AIDS"',
          value: 95.42325114701974,
          color: "#0A2840",
          items: [],
        },
        {
          level: 1,
          name: "Christian Health Association of Ghana",
          value: 95.52922448841042,
          color: "#0A2840",
          items: [],
        },
        {
          level: 1,
          name: "Churches Health Association of Zambia",
          value: 94.82560805542481,
          color: "#0A2840",
          items: [],
        },
        {
          level: 1,
          name: "Community Development Fund",
          value: 96.2820264318884,
          color: "#0A2840",
          items: [],
        },
        {
          level: 1,
          name: "Cooperative Housing Foundation",
          value: 78.23871299021813,
          color: "#0A2840",
          items: [],
        },
        {
          level: 1,
          name: "Coordinating Assembly of Non Governmental Organisation",
          value: 88.95557650783896,
          color: "#0A2840",
          items: [],
        },
        {
          level: 1,
          name: "East, Central and Southern Africa Health Community",
          value: 75.42575775678763,
          color: "#0A2840",
          items: [],
        },
        {
          level: 1,
          name: "Family Health International (FHI360)",
          value: 83.85294860228866,
          color: "#0A2840",
          items: [],
        },
        {
          level: 1,
          name: "Federación Red NICASALUD",
          value: 96.95586973683437,
          color: "#0A2840",
          items: [],
        },
        {
          level: 1,
          name: "Foundation for Innovative New Diagnostics India",
          value: 79.733364912027,
          color: "#0A2840",
          items: [],
        },
        {
          level: 1,
          name: "Frontline AIDS",
          value: 88.76116618865699,
          color: "#0A2840",
          items: [],
        },
        {
          level: 1,
          name: "Fundação para o Desenvolvimento da Comunidade",
          value: 78.38124251580003,
          color: "#0A2840",
          items: [],
        },
        {
          level: 1,
          name: "Ghana-West Africa Program to Combat AIDS - STI",
          value: 99.38660455360564,
          color: "#0A2840",
          items: [],
        },
        {
          level: 1,
          name: "Humanist Institute for Co-operation with Developing Countries",
          value: 101.29417282521229,
          color: "#0A2840",
          items: [],
        },
        {
          level: 1,
          name: "Humanist Institute for Co-operation with Developing Countries (Hivos)",
          value: 95.41200976769322,
          color: "#0A2840",
          items: [],
        },
        {
          level: 1,
          name: "India HIV/AIDS Alliance",
          value: 88.32626415461839,
          color: "#0A2840",
          items: [],
        },
        {
          level: 1,
          name: "Initiative Privée et Communautaire pour la Santé et la Riposte au VIH/SIDA  au Burkina Faso (IPC/BF)",
          value: 99.42704283936949,
          color: "#0A2840",
          items: [],
        },
        {
          level: 1,
          name: "Institute of Human Virology Nigeria",
          value: 97.15400899867156,
          color: "#0A2840",
          items: [],
        },
        {
          level: 1,
          name: "Instituto Dermatológico y Cirugía de Piel ‘Dr. Huberto Bogaert Díaz’",
          value: 101.58513124555793,
          color: "#0A2840",
          items: [],
        },
        {
          level: 1,
          name: "International Centre for Diarrhoeal Disease Research, Bangladesh",
          value: 90.46164007151467,
          color: "#0A2840",
          items: [],
        },
        {
          level: 1,
          name: "International Charitable Foundation “Alliance for Public Health”",
          value: 99.63588062323254,
          color: "#0A2840",
          items: [],
        },
        {
          level: 1,
          name: "International Union Against Tuberculosis and Lung Disease",
          value: 86.89006309844636,
          color: "#0A2840",
          items: [],
        },
        {
          level: 1,
          name: "Kenya Red Cross Society",
          value: 93.11436117422792,
          color: "#0A2840",
          items: [],
        },
        {
          level: 1,
          name: "Konsorsium Komunitas PENABULU-STPI",
          value: 96.66103264169394,
          color: "#0A2840",
          items: [],
        },
        {
          level: 1,
          name: "La Croix-Rouge française",
          value: 89.56942463959311,
          color: "#0A2840",
          items: [],
        },
        {
          level: 1,
          name: "Management Sciences for Health",
          value: 99.9595415772362,
          color: "#0A2840",
          items: [],
        },
        {
          level: 1,
          name: "Mercy Corps",
          value: 98.54767265982582,
          color: "#0A2840",
          items: [],
        },
        {
          level: 1,
          name: "Nai Zindagi",
          value: 95.79238252401487,
          color: "#0A2840",
          items: [],
        },
        {
          level: 1,
          name: "Namibia Network of AIDS Service Organizations",
          value: 96.63681249484667,
          color: "#0A2840",
          items: [],
        },
        {
          level: 1,
          name: "Networking HIV and AIDS Community of Southern Africa NPC",
          value: 92.63468570711156,
          color: "#0A2840",
          items: [],
        },
        {
          level: 1,
          name: "Organisation of Eastern Caribbean States",
          value: 85.64759474475207,
          color: "#0A2840",
          items: [],
        },
        {
          level: 1,
          name: "Organismo Andino de Salud - Convenio Hipólito Unanue",
          value: 87.40359883647801,
          color: "#0A2840",
          items: [],
        },
        {
          level: 1,
          name: "Pact, Inc.",
          value: 106.77373296103838,
          color: "#0A2840",
          items: [],
        },
        {
          level: 1,
          name: "Partners In Health",
          value: 99.14287410878957,
          color: "#0A2840",
          items: [],
        },
        {
          level: 1,
          name: "Persatuan Karya Dharma Kesehatan Indonesia (also known as “PERDHAKI”, Association of Voluntary Health Services of Indonesia)",
          value: 75.8805745848549,
          color: "#0A2840",
          items: [],
        },
        {
          level: 1,
          name: "Philippine Business for Social Progress, Inc.",
          value: 95.63671338799881,
          color: "#0A2840",
          items: [],
        },
        {
          level: 1,
          name: "Plan International (India Chapter)",
          value: 75.96399954350646,
          color: "#0A2840",
          items: [],
        },
        {
          level: 1,
          name: "Plan International El Salvador",
          value: 76.73306462010004,
          color: "#0A2840",
          items: [],
        },
        {
          level: 1,
          name: "Plan International, Inc.",
          value: 91.43887010060563,
          color: "#0A2840",
          items: [],
        },
        {
          level: 1,
          name: "Population Services International",
          value: 99.86616416385964,
          color: "#0A2840",
          items: [],
        },
        {
          level: 1,
          name: "Prévention Information Lutte contre le Sida",
          value: 105.9814452804319,
          color: "#0A2840",
          items: [],
        },
        {
          level: 1,
          name: "Raks Thai Foundation",
          value: 78.12999419863944,
          color: "#0A2840",
          items: [],
        },
        {
          level: 1,
          name: "SADC Malaria Elimination Eight Secretariat",
          value: 98.64750701260525,
          color: "#0A2840",
          items: [],
        },
        {
          level: 1,
          name: "SANRU Asbl",
          value: 95.09735786330491,
          color: "#0A2840",
          items: [],
        },
        {
          level: 1,
          name: "Save the Children Federation, Inc.",
          value: 95.16636113797344,
          color: "#0A2840",
          items: [],
        },
        {
          level: 1,
          name: "Society For Family Health",
          value: 100.89435359715577,
          color: "#0A2840",
          items: [],
        },
        {
          level: 1,
          name: "Socios en Salud sucursal Peru",
          value: 74.46846560279316,
          color: "#0A2840",
          items: [],
        },
        {
          level: 1,
          name: "Solidarity and Action Against The HIV Infection in India",
          value: 78.65034673699688,
          color: "#0A2840",
          items: [],
        },
        {
          level: 1,
          name: "St. Petersburg charitable fund programs “Humanitarian action”",
          value: 70.26968760536182,
          color: "#0A2840",
          items: [],
        },
        {
          level: 1,
          name: "Stichting Cordaid",
          value: 94.2321671745416,
          color: "#0A2840",
          items: [],
        },
        {
          level: 1,
          name: "T.C.I. Foundation",
          value: 53.22796461021107,
          color: "#0A2840",
          items: [],
        },
        {
          level: 1,
          name: "The AIDS Support Organisation (Uganda) Limited",
          value: 77.6155721345757,
          color: "#0A2840",
          items: [],
        },
        {
          level: 1,
          name: "The Family Planning Association of Sri Lanka",
          value: 103.4086473776598,
          color: "#0A2840",
          items: [],
        },
        {
          level: 1,
          name: "Viet Nam Union of Science and Technology Associations",
          value: 102.05285526982671,
          color: "#0A2840",
          items: [],
        },
        {
          level: 1,
          name: "World Vision (PNG) Trust",
          value: 90.56657453362547,
          color: "#0A2840",
          items: [],
        },
        {
          level: 1,
          name: "World Vision International",
          value: 82.86646045695335,
          color: "#0A2840",
          items: [],
        },
        {
          level: 1,
          name: "World Vision Malawi",
          value: 99.42771970516762,
          color: "#0A2840",
          items: [],
        },
        {
          level: 1,
          name: "Yayasan Spiritia",
          value: 80.73598172843907,
          color: "#0A2840",
          items: [],
        },
      ],
    },
    {
      level: 0,
      name: "Governmental Organization",
      value: 85.76188986627884,
      color: "#0A2840",
      items: [
        {
          level: 1,
          name: "Alliance Nationale des Communautés pour la Santé - Sénégal",
          value: 100.92235039396851,
          color: "#0A2840",
          items: [],
        },
        {
          level: 1,
          name: "Australian Federation Of AIDS Organisations Limited",
          value: 98.1033622731952,
          color: "#0A2840",
          items: [],
        },
        {
          level: 1,
          name: "Botswana-Ministry of Health",
          value: 60.85238684805741,
          color: "#0A2840",
          items: [],
        },
        {
          level: 1,
          name: "Cambodia Ministry of Economy and Finance",
          value: 81.46673548297635,
          color: "#0A2840",
          items: [],
        },
        {
          level: 1,
          name: "Cellule Nationale de Coordination Technique de la Riposte Nationale au Sida et aux Hépatites",
          value: 100.49295798595432,
          color: "#0A2840",
          items: [],
        },
        {
          level: 1,
          name: "Conseil National de Lutte contre le SIDA de la République du Sénégal",
          value: 85.3061557678377,
          color: "#0A2840",
          items: [],
        },
        {
          level: 1,
          name: "Conseil National de Lutte contre le VIH/SIDA, la Tuberculose, le Paludisme, les Hépatites, les Infections sexuellement transmissibles et les Epidémies",
          value: 55.32450287022682,
          color: "#0A2840",
          items: [],
        },
        {
          level: 1,
          name: "Consejo Nacional para el VIH y el SIDA",
          value: 88.62308615196677,
          color: "#0A2840",
          items: [],
        },
        {
          level: 1,
          name: "Coordination Committee of the Fight against AIDS of Cabo Verde",
          value: 77.93487908311216,
          color: "#0A2840",
          items: [],
        },
        {
          level: 1,
          name: "Department of Disease Control, Ministry of Public Health of the Royal Government of Thailand",
          value: 94.75314789966811,
          color: "#0A2840",
          items: [],
        },
        {
          level: 1,
          name: "Department of Economic Affairs, Ministry of Finance of India",
          value: 76.38705217048405,
          color: "#0A2840",
          items: [],
        },
        {
          level: 1,
          name: "Directorate General of Disease Prevention and Control, Ministry of Health of The Republic of Indonesia",
          value: 85.81323691610525,
          color: "#0A2840",
          items: [],
        },
        {
          level: 1,
          name: "Directorate of Malaria Control, Ministry of National Health Services, Regulations and Coordination of Pakistan",
          value: 100.30429006020134,
          color: "#0A2840",
          items: [],
        },
        {
          level: 1,
          name: "Economic Relations Division, Ministry of Finance of the People's Republic of Bangladesh",
          value: 66.44622824997731,
          color: "#0A2840",
          items: [],
        },
        {
          level: 1,
          name: "Empresa Nacional Promotora del Desarrollo Territorial",
          value: 83.8486493111898,
          color: "#0A2840",
          items: [],
        },
        {
          level: 1,
          name: "Federal Ministry of Health of the Federal Democratic Republic of Ethiopia",
          value: 90.6515468313537,
          color: "#0A2840",
          items: [],
        },
        {
          level: 1,
          name: "Federal Ministry of Health of the Republic of Sudan",
          value: 89.71187464570576,
          color: "#0A2840",
          items: [],
        },
        {
          level: 1,
          name: "HIV/AIDS Prevention and Control Office",
          value: 80.65678562579191,
          color: "#0A2840",
          items: [],
        },
        {
          level: 1,
          name: "Indonesia AIDS Coalition",
          value: 62.919179015263715,
          color: "#0A2840",
          items: [],
        },
        {
          level: 1,
          name: "Instituto Nicaragüense de Seguridad Social",
          value: 142.3038269650457,
          color: "#0A2840",
          items: [],
        },
        {
          level: 1,
          name: "Intergovernmental Authority on Development",
          value: 98.3100948988293,
          color: "#0A2840",
          items: [],
        },
        {
          level: 1,
          name: "Kazakh scientific center of dermatology and infectious diseases of the Ministry of Health of the Republic of Kazakhstan",
          value: 101.81286786117214,
          color: "#0A2840",
          items: [],
        },
        {
          level: 1,
          name: "Lagos State Ministry of Health",
          value: 105.08627643179591,
          color: "#0A2840",
          items: [],
        },
        {
          level: 1,
          name: "Lubombo Spatial Development Initiative 2 NPC",
          value: 92.78929279218553,
          color: "#0A2840",
          items: [],
        },
        {
          level: 1,
          name: "Malaysian AIDS Council",
          value: 81.45338557212331,
          color: "#0A2840",
          items: [],
        },
        {
          level: 1,
          name: "Ministry of Finance of the Kingdom of Lesotho",
          value: 87.21646833438442,
          color: "#0A2840",
          items: [],
        },
        {
          level: 1,
          name: "Ministry of Finance of the United Republic of Tanzania",
          value: 81.70216922242767,
          color: "#0A2840",
          items: [],
        },
        {
          level: 1,
          name: "Ministry of Finance, Planning and Economic Development of the Republic of Uganda",
          value: 71.64719591415889,
          color: "#0A2840",
          items: [],
        },
        {
          level: 1,
          name: "Ministry of Health",
          value: 91.29091163479063,
          color: "#0A2840",
          items: [],
        },
        {
          level: 1,
          name: "Ministry of Health and Social Action of the Republic of Senegal",
          value: 81.75649746225993,
          color: "#0A2840",
          items: [],
        },
        {
          level: 1,
          name: "Ministry of Health and Social Assistance of the Republic of Guatemala",
          value: 98.09299346822489,
          color: "#0A2840",
          items: [],
        },
        {
          level: 1,
          name: "Ministry of Health and Social Development of the Republic of Mali",
          value: 47.108581691093825,
          color: "#0A2840",
          items: [],
        },
        {
          level: 1,
          name: "Ministry of Health and Social Protection of the Republic of Albania",
          value: 110.96600776648506,
          color: "#0A2840",
          items: [],
        },
        {
          level: 1,
          name: "Ministry of Health and Social Services of Namibia",
          value: 89.91639890826903,
          color: "#0A2840",
          items: [],
        },
        {
          level: 1,
          name: "Ministry of Health and Social Welfare",
          value: 82.65414852556849,
          color: "#0A2840",
          items: [],
        },
        {
          level: 1,
          name: "Ministry of Health and Wellness",
          value: 61.16131447401955,
          color: "#0A2840",
          items: [],
        },
        {
          level: 1,
          name: "Ministry of Health and Wellness,  Jamaica",
          value: 97.63237279061472,
          color: "#0A2840",
          items: [],
        },
        {
          level: 1,
          name: "Ministry of Health of Mongolia",
          value: 91.58372608964956,
          color: "#0A2840",
          items: [],
        },
        {
          level: 1,
          name: "Ministry of Health of Montenegro",
          value: 81.36149266731636,
          color: "#0A2840",
          items: [],
        },
        {
          level: 1,
          name: "Ministry of Health of Mozambique",
          value: 84.70410926090535,
          color: "#0A2840",
          items: [],
        },
        {
          level: 1,
          name: "Ministry of Health of Republic of Serbia",
          value: 90.07173955300006,
          color: "#0A2840",
          items: [],
        },
        {
          level: 1,
          name: "Ministry of Health of Romania",
          value: 70.13133885461426,
          color: "#0A2840",
          items: [],
        },
        {
          level: 1,
          name: "Ministry of Health of Sao Tome and Principe",
          value: 88.46865417399526,
          color: "#0A2840",
          items: [],
        },
        {
          level: 1,
          name: "Ministry of Health of the Co-operative Republic of Guyana",
          value: 91.00486215855969,
          color: "#0A2840",
          items: [],
        },
        {
          level: 1,
          name: "Ministry of Health of the Democratic Republic of Timor-Leste",
          value: 97.6477818884732,
          color: "#0A2840",
          items: [],
        },
        {
          level: 1,
          name: "Ministry of Health of the Democratic Socialist  Republic of Sri Lanka",
          value: 77.21093248567722,
          color: "#0A2840",
          items: [],
        },
        {
          level: 1,
          name: "Ministry of Health of the Kingdom of Morocco",
          value: 96.58242994559535,
          color: "#0A2840",
          items: [],
        },
        {
          level: 1,
          name: "Ministry of Health of the Lao People's Democratic Republic",
          value: 97.4339690265088,
          color: "#0A2840",
          items: [],
        },
        {
          level: 1,
          name: "Ministry of Health of the Republic of Angola",
          value: 101.26476139428019,
          color: "#0A2840",
          items: [],
        },
        {
          level: 1,
          name: "Ministry of Health of the Republic of Armenia",
          value: 94.52695305637498,
          color: "#0A2840",
          items: [],
        },
        {
          level: 1,
          name: "Ministry of Health of the Republic of Azerbaijan",
          value: 93.58375420729612,
          color: "#0A2840",
          items: [],
        },
        {
          level: 1,
          name: "Ministry of Health of the Republic of El Salvador",
          value: 62.49240965243551,
          color: "#0A2840",
          items: [],
        },
        {
          level: 1,
          name: "Ministry of Health of the Republic of Ghana",
          value: 100.28590085850344,
          color: "#0A2840",
          items: [],
        },
        {
          level: 1,
          name: "Ministry of Health of the Republic of Guinea-Bissau",
          value: 77.9410431750981,
          color: "#0A2840",
          items: [],
        },
        {
          level: 1,
          name: "Ministry of Health of the Republic of Malawi",
          value: 98.08863402837714,
          color: "#0A2840",
          items: [],
        },
        {
          level: 1,
          name: "Ministry of Health of the Republic of Rwanda",
          value: 91.96043249643331,
          color: "#0A2840",
          items: [],
        },
        {
          level: 1,
          name: "Ministry of Health of the Republic of Suriname",
          value: 72.11778355902794,
          color: "#0A2840",
          items: [],
        },
        {
          level: 1,
          name: "Ministry of Health of the Republic of The Gambia",
          value: 98.50466766841114,
          color: "#0A2840",
          items: [],
        },
        {
          level: 1,
          name: "Ministry of Health of the Republic of Zambia",
          value: 85.54924684603846,
          color: "#0A2840",
          items: [],
        },
        {
          level: 1,
          name: "Ministry of Health of the Revolutionary Government of Zanzibar",
          value: 85.63901357240283,
          color: "#0A2840",
          items: [],
        },
        {
          level: 1,
          name: "Ministry of Health of the Royal Government of Bhutan",
          value: 102.35281532792864,
          color: "#0A2840",
          items: [],
        },
        {
          level: 1,
          name: "Ministry of Health of the State of Eritrea",
          value: 77.36805642258317,
          color: "#0A2840",
          items: [],
        },
        {
          level: 1,
          name: "Ministry of Health, Population and Hospital Reform of the People's Democratic Republic of Algeria",
          value: 134.57394168666843,
          color: "#0A2840",
          items: [],
        },
        {
          level: 1,
          name: "Ministry of Health, Public Hygiene and Universal Health Coverage of the Republic of Côte d’Ivoire",
          value: 88.04390474170562,
          color: "#0A2840",
          items: [],
        },
        {
          level: 1,
          name: "Ministry of Health, Solidarity, Social Protection and Gender Promotion of the Union of the Comoros",
          value: 89.75632218420988,
          color: "#0A2840",
          items: [],
        },
        {
          level: 1,
          name: "Ministry of National Health Services, Regulations and Coordination of Pakistan",
          value: 100.17823503570058,
          color: "#0A2840",
          items: [],
        },
        {
          level: 1,
          name: "Ministry of Public Health and Fight against AIDS of the Republic of Burundi",
          value: 90.3970374542882,
          color: "#0A2840",
          items: [],
        },
        {
          level: 1,
          name: "Ministry of Public Health and Prevention",
          value: 92.87542682309375,
          color: "#0A2840",
          items: [],
        },
        {
          level: 1,
          name: "Ministry of Public Health and Social Assistance of the Dominican Republic",
          value: 114.97550631471928,
          color: "#0A2840",
          items: [],
        },
        {
          level: 1,
          name: "Ministry of Public Health of the Islamic Republic of Afghanistan",
          value: 94.51531797127008,
          color: "#0A2840",
          items: [],
        },
        {
          level: 1,
          name: "Ministry of Public Health of the Republic of Cameroon",
          value: 87.15058621746589,
          color: "#0A2840",
          items: [],
        },
        {
          level: 1,
          name: "Ministry of Public Health of the Republic of Ecuador",
          value: 97.48209851935155,
          color: "#0A2840",
          items: [],
        },
        {
          level: 1,
          name: "Ministry of Public Health of the Republic of Madagascar",
          value: 81.11096975583145,
          color: "#0A2840",
          items: [],
        },
        {
          level: 1,
          name: "Ministry of Public Health, Hygiene and Prevention",
          value: 80.46337972835535,
          color: "#0A2840",
          items: [],
        },
        {
          level: 1,
          name: "Ministry of Public Health, Population and Social Affairs",
          value: 67.35436066258077,
          color: "#0A2840",
          items: [],
        },
        {
          level: 1,
          name: "Ministère de la Santé Publique et de la Population (Unité de Gestion des Projets)",
          value: 52.89474011835345,
          color: "#0A2840",
          items: [],
        },
        {
          level: 1,
          name: "National Agency for the Control of AIDS",
          value: 61.46397909217322,
          color: "#0A2840",
          items: [],
        },
        {
          level: 1,
          name: "National Center For Disease Control and Public Health",
          value: 98.82536155552847,
          color: "#0A2840",
          items: [],
        },
        {
          level: 1,
          name: "National Department of Health of the Republic of South Africa",
          value: 100.84848603374448,
          color: "#0A2840",
          items: [],
        },
        {
          level: 1,
          name: "National Emergency Response Council on HIV and AIDS",
          value: 93.56834672254337,
          color: "#0A2840",
          items: [],
        },
        {
          level: 1,
          name: "National Executive Secretariat for fight against Aids of the Islamic Republic of Mauritania",
          value: 88.15173826938792,
          color: "#0A2840",
          items: [],
        },
        {
          level: 1,
          name: "National HIV/AIDS Secretariat",
          value: 100.71653192817651,
          color: "#0A2840",
          items: [],
        },
        {
          level: 1,
          name: "National High Council for the Fight against AIDS",
          value: 100.22078290229477,
          color: "#0A2840",
          items: [],
        },
        {
          level: 1,
          name: "National Malaria Elimination Programme of the Federal Ministry of Health of the Federal Republic of Nigeria",
          value: 101.87048587917738,
          color: "#0A2840",
          items: [],
        },
        {
          level: 1,
          name: "National TB Control Programme Pakistan",
          value: 71.48293920941889,
          color: "#0A2840",
          items: [],
        },
        {
          level: 1,
          name: "National Treasury of the Republic of Kenya",
          value: 92.29395748771161,
          color: "#0A2840",
          items: [],
        },
        {
          level: 1,
          name: "National Tuberculosis & Leprosy Control Programme",
          value: 72.01752376092962,
          color: "#0A2840",
          items: [],
        },
        {
          level: 1,
          name: "National Tuberculosis Programme of the Republic of Benin",
          value: 88.6858578533845,
          color: "#0A2840",
          items: [],
        },
        {
          level: 1,
          name: "Office National de Nutrition",
          value: 101.18805079394917,
          color: "#0A2840",
          items: [],
        },
        {
          level: 1,
          name: "Office National de la Famille et de la Population de la République de Tunisie",
          value: 104.20194586141542,
          color: "#0A2840",
          items: [],
        },
        {
          level: 1,
          name: "Primature de la République Togolaise",
          value: 95.71778260031162,
          color: "#0A2840",
          items: [],
        },
        {
          level: 1,
          name: "Programme National de Lutte contre la Tuberculose et la Lepre",
          value: 99.99944890802642,
          color: "#0A2840",
          items: [],
        },
        {
          level: 1,
          name: "Programme National de Lutte contre le Paludisme de la République du Bénin",
          value: 101.10439010337828,
          color: "#0A2840",
          items: [],
        },
        {
          level: 1,
          name: "Programme d'Appui au Développement Sanitaire (PADS)",
          value: 90.72086611361098,
          color: "#0A2840",
          items: [],
        },
        {
          level: 1,
          name: "Programme santé de lutte contre le Sida",
          value: 93.43547788374958,
          color: "#0A2840",
          items: [],
        },
        {
          level: 1,
          name: "Public Institution - Coordination, Implementation and Monitoring Unit of the Health  System Projects",
          value: 101.56071914662692,
          color: "#0A2840",
          items: [],
        },
        {
          level: 1,
          name: "RSE on REU “National Scientific Center of Phthisiopulmonology of the Republic of  Kazakhstan” of the Ministry of Health of the Republic of  Kazakhstan",
          value: 96.03827076347736,
          color: "#0A2840",
          items: [],
        },
        {
          level: 1,
          name: "Republican Center of Tuberculosis Control - Tajikistan",
          value: 107.80755745916481,
          color: "#0A2840",
          items: [],
        },
        {
          level: 1,
          name: "Republican Center to Fight AIDS",
          value: 87.06712511485502,
          color: "#0A2840",
          items: [],
        },
        {
          level: 1,
          name: "Republican DOTS Centre  Ministry of Health of the Republic of Uzbekistan",
          value: 103.80879075598632,
          color: "#0A2840",
          items: [],
        },
        {
          level: 1,
          name: "Republican Scientific and Practical Center for Medical Technologies, Informatization, Administration and Management of Health",
          value: 87.88656667283254,
          color: "#0A2840",
          items: [],
        },
        {
          level: 1,
          name: "Republican Specialized Scientific-Practical Medical Center of Phthisiology and Pulmonology",
          value: 99.77603518882098,
          color: "#0A2840",
          items: [],
        },
        {
          level: 1,
          name: "Secrétariat Permanent du Conseil National de Lutte contre le Sida et les Infections Sexuellement Transmissibles (SP/CNLS-IST)",
          value: 94.91092607818102,
          color: "#0A2840",
          items: [],
        },
        {
          level: 1,
          name: "Social Security Board",
          value: 83.35457895245943,
          color: "#0A2840",
          items: [],
        },
        {
          level: 1,
          name: "Solomon Islands Ministry of Health and Medical Services",
          value: 59.72665870663085,
          color: "#0A2840",
          items: [],
        },
        {
          level: 1,
          name: 'State Institution "Public Health Center of the Ministry of Health of Ukraine"',
          value: 85.53351055226472,
          color: "#0A2840",
          items: [],
        },
        {
          level: 1,
          name: "Sécrétariat Exécutif du Comité National de Lutte Contre le VIH/SIDA de la République de Madagascar",
          value: 102.90358219070448,
          color: "#0A2840",
          items: [],
        },
        {
          level: 1,
          name: "The Ministry of Health and Child Care of the Republic of Zimbabwe",
          value: 87.42559792536626,
          color: "#0A2840",
          items: [],
        },
        {
          level: 1,
          name: "The Ministry of Health of the Republic of Guinea",
          value: 98.17585141392922,
          color: "#0A2840",
          items: [],
        },
        {
          level: 1,
          name: "The National AIDS Secretariat of the Republic of The Gambia",
          value: 101.72283647949052,
          color: "#0A2840",
          items: [],
        },
        {
          level: 1,
          name: "Viet Nam Authority of HIV/AIDS Control",
          value: 99.77533866220159,
          color: "#0A2840",
          items: [],
        },
        {
          level: 1,
          name: "Viet Nam National Lung Hospital",
          value: 95.00874878040942,
          color: "#0A2840",
          items: [],
        },
      ],
    },
    {
      level: 0,
      name: "Multilateral Organization",
      value: 88.3375121527742,
      color: "#0A2840",
      items: [
        {
          level: 1,
          name: "African Comprehensive HIV/AIDS Partnerships",
          value: 97.6034366823948,
          color: "#0A2840",
          items: [],
        },
        {
          level: 1,
          name: "Institute of Nutrition of Central America and Panama (INCAP)",
          value: 93.09980161239493,
          color: "#0A2840",
          items: [],
        },
        {
          level: 1,
          name: "Inter-American Development Bank",
          value: 2.0515308854102465,
          color: "#0A2840",
          items: [],
        },
        {
          level: 1,
          name: "International Bank for Reconstruction and Development",
          value: 100,
          color: "#0A2840",
          items: [],
        },
        {
          level: 1,
          name: "International Organization for Migration",
          value: 90.0680610285576,
          color: "#0A2840",
          items: [],
        },
        {
          level: 1,
          name: "United Nations Children's Fund",
          value: 92.80995932435665,
          color: "#0A2840",
          items: [],
        },
        {
          level: 1,
          name: "United Nations Development Programme",
          value: 89.28458501005485,
          color: "#0A2840",
          items: [],
        },
        {
          level: 1,
          name: "United Nations Office for Project Services",
          value: 84.65204206582902,
          color: "#0A2840",
          items: [],
        },
      ],
    },
    {
      level: 0,
      name: "Private Sector",
      value: 93.11676013384232,
      color: "#0A2840",
      items: [
        {
          level: 1,
          name: "AngloGold Ashanti (Ghana) Malaria Control Limited",
          value: 101.2451328258146,
          color: "#0A2840",
          items: [],
        },
        {
          level: 1,
          name: "Indus Hospital & Health Network",
          value: 83.94441008252653,
          color: "#0A2840",
          items: [],
        },
        {
          level: 1,
          name: "Pilipinas Shell Foundation, Inc.",
          value: 97.25553724081402,
          color: "#0A2840",
          items: [],
        },
        {
          level: 1,
          name: "The Rotary Club of Port Moresby Inc.",
          value: 98.57423254834339,
          color: "#0A2840",
          items: [],
        },
        {
          level: 1,
          name: "William J. Clinton Foundation",
          value: 77.34397371273892,
          color: "#0A2840",
          items: [],
        },
        {
          level: 1,
          name: "Wits Health Consortium (Pty) Ltd",
          value: 110.76280220549614,
          color: "#0A2840",
          items: [],
        },
      ],
    },
  ],
};
