import { appColors } from "app/theme";

export interface ItemModel {
  name: string;
  level: number;
  expanded: boolean;
  children?: ItemModel[];
}

export interface HeatmapDataItem {
  row: string;
  column: string;
  parentRow?: string;
  percentage?: number;
  parentColumn?: string;
  value: number | string;
  [key: string]: any;
}

export interface HeatmapProps {
  rowCategory: string;
  columnCategory: string;
  data: HeatmapDataItem[];
  hoveredLegend: string | null;
  valueType: "percentage" | "amount";
  itemWidth?: number;
  contentProp: string;
  getItemColor: (item?: HeatmapDataItem) => string;
  bgColor?: string;
  noItemOrdering?: boolean;
  columnHeader?: string;
  rowHeader?: string;
  noLegend?: boolean;
  expandAll?: boolean;
}

export function getPercentageColor(item?: HeatmapDataItem) {
  if (!item) return "#fff";
  const value = item.percentage;
  const actualValue = item.value as number;

  if (!value || actualValue === 0) return "#fff";

  if (value > 100) {
    return appColors.HEATMAP.CHART_PERCENTAGE_COLORS[4];
  }
  if (value >= 85) {
    return appColors.HEATMAP.CHART_PERCENTAGE_COLORS[0];
  }
  if (value >= 75) {
    return appColors.HEATMAP.CHART_PERCENTAGE_COLORS[1];
  }
  if (value >= 65) {
    return appColors.HEATMAP.CHART_PERCENTAGE_COLORS[2];
  }
  return appColors.HEATMAP.CHART_PERCENTAGE_COLORS[3];
}

export function getEligibilityColor(item?: HeatmapDataItem) {
  if (!item) return appColors.HEATMAP.CHART_ELIGIBILITY_COLORS[3];
  const value = item.value as string;
  switch (value) {
    case "Eligible":
      return appColors.HEATMAP.CHART_ELIGIBILITY_COLORS[0];
    case "Transition":
      return appColors.HEATMAP.CHART_ELIGIBILITY_COLORS[1];
    case "Not Eligible":
      return appColors.HEATMAP.CHART_ELIGIBILITY_COLORS[2];
    default:
      return appColors.HEATMAP.CHART_ELIGIBILITY_COLORS[3];
  }
}

export const STORY_DATA_VARIANT_1: HeatmapDataItem[] = [
  {
    row: "Ministry of Health of the Democratic Republic of Timor-Leste",
    parentRow: "Governmental Organization",
    column: "Tuberculosis",
    value: 8925949.75,
    budget: 9142989.68,
    percentage: 98,
  },
  {
    row: "Plan International El Salvador",
    parentRow: "Civil Society Organization",
    column: "HIV/AIDS",
    value: 2747414.54,
    budget: 3971199.9,
    percentage: 69,
  },
  {
    row: "National Malaria Elimination Programme of the Federal Ministry of Health of the Federal Republic of Nigeria",
    parentRow: "Governmental Organization",
    column: "Malaria",
    value: 83418088.6,
    budget: 94110301.55,
    percentage: 89,
  },
  {
    row: "International Charitable Foundation “Alliance for Public Health”",
    parentRow: "Civil Society Organization",
    column: "HIV/AIDS",
    value: 18588624.36,
    budget: 19543954.61,
    percentage: 95,
  },
  {
    row: "Ministry of Public Health, Hygiene and Prevention",
    parentRow: "Governmental Organization",
    column: "Malaria",
    value: 93486530.46,
    budget: 125683441.02,
    percentage: 74,
  },
  {
    row: "Republican Center of Tuberculosis Control - Tajikistan",
    parentRow: "Governmental Organization",
    column: "Tuberculosis",
    value: 10679073.07,
    budget: 11172359.29,
    percentage: 96,
  },
  {
    row: "Ministry of Health of Romania",
    parentRow: "Governmental Organization",
    column: "Tuberculosis",
    value: 7891178.27,
    budget: 11566303.61,
    percentage: 68,
  },
  {
    row: "Department of Economic Affairs, Ministry of Finance of India",
    parentRow: "Governmental Organization",
    column: "Tuberculosis",
    value: 253343330.19,
    budget: 259120995.18,
    percentage: 98,
  },
  {
    row: "Ministry of Public Health, Hygiene and Prevention",
    parentRow: "Governmental Organization",
    column: "HIV/AIDS",
    value: 30980493,
    budget: 36914187.84,
    percentage: 84,
  },
  {
    row: "Initiative Privée et Communautaire pour la Santé et la Riposte au VIH/SIDA  au Burkina Faso (IPC/BF)",
    parentRow: "Civil Society Organization",
    column: "HIV/TB",
    value: 15181150.96,
    budget: 15686493.31,
    percentage: 97,
  },
  {
    row: "Ministry of Health of the Democratic Socialist  Republic of Sri Lanka",
    parentRow: "Governmental Organization",
    column: "Malaria",
    value: 2374878,
    budget: 2499878,
    percentage: 95,
  },
  {
    row: "Caribbean Community (CARICOM)",
    parentRow: "Civil Society Organization",
    column: "HIV/AIDS",
    value: 8137744.28,
    budget: 10270098.17,
    percentage: 79,
  },
  {
    row: "Ministry of Health of the Democratic Republic of Timor-Leste",
    parentRow: "Governmental Organization",
    column: "HIV/AIDS",
    value: 7254692.66,
    budget: 7494078.05,
    percentage: 97,
  },
  {
    row: "Save the Children Federation, Inc.",
    parentRow: "Civil Society Organization",
    column: "HIV/AIDS",
    value: 170340210.28,
    budget: 195138622.92,
    percentage: 87,
  },
  {
    row: "Ministry of Health of the Revolutionary Government of Zanzibar",
    parentRow: "Governmental Organization",
    column: "HIV/TB",
    value: 10621149.77,
    budget: 10770743.6,
    percentage: 99,
  },
  {
    row: "Republican Center to Fight AIDS",
    parentRow: "Governmental Organization",
    column: "HIV/AIDS",
    value: 16392409.89,
    budget: 18568104.7,
    percentage: 88,
  },
  {
    row: "Alliance Nationale pour la Santé et le Développement en Côte D'Ivoire",
    parentRow: "Civil Society Organization",
    column: "HIV/AIDS",
    value: 21325716.64,
    budget: 27731698.28,
    percentage: 77,
  },
  {
    row: "Population Services International",
    parentRow: "Civil Society Organization",
    column: "Malaria",
    value: 222899914.41,
    budget: 235461547.51,
    percentage: 95,
  },
  {
    row: "Programme d'Appui au Développement Sanitaire (PADS)",
    parentRow: "Governmental Organization",
    column: "Tuberculosis",
    value: 13716448.48,
    budget: 17164446.59,
    percentage: 80,
  },
  {
    row: "HIV/AIDS Prevention and Control Office",
    parentRow: "Governmental Organization",
    column: "HIV/AIDS",
    value: 415430031.98,
    budget: 479973014.1,
    percentage: 87,
  },
  {
    row: "Ministry of Public Health and Social Assistance of the Dominican Republic",
    parentRow: "Governmental Organization",
    column: "Tuberculosis",
    value: 5043679.86,
    budget: 5303073.28,
    percentage: 95,
  },
  {
    row: "Ministry of Public Health, Population and Social Affairs",
    parentRow: "Governmental Organization",
    column: "HIV/AIDS",
    value: 15947797.93,
    budget: 32346642,
    percentage: 49,
  },
  {
    row: "Ministry of Health and Social Development of the Republic of Mali",
    parentRow: "Governmental Organization",
    column: "RSSH",
    value: 17120969.5,
    budget: 54733766.98,
    percentage: 31,
  },
  {
    row: "Ministry of Health of the Democratic Socialist  Republic of Sri Lanka",
    parentRow: "Governmental Organization",
    column: "HIV/AIDS",
    value: 3904580.64,
    budget: 7674715.02,
    percentage: 51,
  },
  {
    row: "Republican Center to Fight AIDS",
    parentRow: "Governmental Organization",
    column: "HIV/TB",
    value: 23176495.24,
    budget: 31364406.38,
    percentage: 74,
  },
  {
    row: "Christian Health Association of Ghana",
    parentRow: "Civil Society Organization",
    column: "HIV/TB",
    value: 15374761,
    budget: 14280527.6,
    percentage: 108,
  },
  {
    row: "Ministry of Health and Wellness,  Jamaica",
    parentRow: "Governmental Organization",
    column: "HIV/AIDS",
    value: 15122559.31,
    budget: 19533881.31,
    percentage: 77,
  },
  {
    row: "Ministry of Health of the Republic of Azerbaijan",
    parentRow: "Governmental Organization",
    column: "Tuberculosis",
    value: 8636785.3,
    budget: 8757110.95,
    percentage: 99,
  },
  {
    row: "Ministry of Health of Mozambique",
    parentRow: "Governmental Organization",
    column: "Malaria",
    value: 231158397.22,
    budget: 281680084.58,
    percentage: 82,
  },
  {
    row: "Cameroon National Association for Family Welfare",
    parentRow: "Civil Society Organization",
    column: "HIV/AIDS",
    value: 22389029.96,
    budget: 24421712.51,
    percentage: 92,
  },
  {
    row: "Ministry of Health, Solidarity, Social Protection and Gender Promotion of the Union of the Comoros",
    parentRow: "Governmental Organization",
    column: "HIV/AIDS",
    value: 1484674.35,
    budget: 1541976.95,
    percentage: 96,
  },
  {
    row: "Save the Children Federation, Inc.",
    parentRow: "Civil Society Organization",
    column: "Malaria",
    value: 73900665.7,
    budget: 89746653.08,
    percentage: 82,
  },
  {
    row: "Federal Ministry of Health of the Federal Democratic Republic of Ethiopia",
    parentRow: "Governmental Organization",
    column: "RSSH",
    value: 63382704.31,
    budget: 118016317.23,
    percentage: 54,
  },
  {
    row: "Central Board of ‘Aisyiyah",
    parentRow: "Civil Society Organization",
    column: "Tuberculosis",
    value: 14078982.86,
    budget: 14768459,
    percentage: 95,
  },
  {
    row: "Ministry of Health of the State of Eritrea",
    parentRow: "Governmental Organization",
    column: "Tuberculosis",
    value: 7000846.39,
    budget: 9337763.93,
    percentage: 75,
  },
  {
    row: "Solomon Islands Ministry of Health and Medical Services",
    parentRow: "Governmental Organization",
    column: "Malaria",
    value: 5660058.98,
    budget: 12013247.18,
    percentage: 47,
  },
  {
    row: "Ministry of Finance, Planning and Economic Development of the Republic of Uganda",
    parentRow: "Governmental Organization",
    column: "Tuberculosis",
    value: 47135187.92,
    budget: 55783263.8,
    percentage: 84,
  },
  {
    row: "Ministry of Public Health and Prevention",
    parentRow: "Governmental Organization",
    column: "HIV/AIDS",
    value: 39582812.51,
    budget: 46022818.97,
    percentage: 86,
  },
  {
    row: "Ministry of Health of the Democratic Republic of Timor-Leste",
    parentRow: "Governmental Organization",
    column: "Malaria",
    value: 9475580.99,
    budget: 9859443.27,
    percentage: 96,
  },
  {
    row: "Ministry of Public Health of the Republic of Madagascar",
    parentRow: "Governmental Organization",
    column: "RSSH",
    value: 14529015.94,
    budget: 29119903.61,
    percentage: 50,
  },
  {
    row: "Amref Health Africa",
    parentRow: "Civil Society Organization",
    column: "Tuberculosis",
    value: 79076895.69,
    budget: 121179258.68,
    percentage: 65,
  },
  {
    row: "AIDS Foundation South Africa",
    parentRow: "Civil Society Organization",
    column: "HIV/TB",
    value: 84597677.04,
    budget: 124536903.61,
    percentage: 68,
  },
  {
    row: "Ministry of Health of the Royal Government of Bhutan",
    parentRow: "Governmental Organization",
    column: "HIV/AIDS",
    value: 1027502,
    budget: 1149153.17,
    percentage: 89,
  },
  {
    row: "National Tuberculosis & Leprosy Control Programme",
    parentRow: "Governmental Organization",
    column: "Tuberculosis",
    value: 106130620,
    budget: 132696549.57,
    percentage: 80,
  },
  {
    row: "International Centre for Diarrhoeal Disease Research, Bangladesh",
    parentRow: "Civil Society Organization",
    column: "HIV/AIDS",
    value: 13432755.12,
    budget: 15418794.08,
    percentage: 87,
  },
  {
    row: "The Ministry of Health and Child Care of the Republic of Zimbabwe",
    parentRow: "Governmental Organization",
    column: "Malaria",
    value: 165775805.83,
    budget: 218095601.75,
    percentage: 76,
  },
  {
    row: "Ministry of Health of Mozambique",
    parentRow: "Governmental Organization",
    column: "HIV/AIDS",
    value: 576446535.45,
    budget: 714295875.65,
    percentage: 81,
  },
  {
    row: "Intergovernmental Authority on Development",
    parentRow: "Governmental Organization",
    column: "Tuberculosis",
    value: 9758625.92,
    budget: 11707065.79,
    percentage: 83,
  },
  {
    row: "Society For Family Health",
    parentRow: "Civil Society Organization",
    column: "HIV/AIDS",
    value: 13520682.48,
    budget: 15894545.42,
    percentage: 85,
  },
  {
    row: "Population Services International",
    parentRow: "Civil Society Organization",
    column: "HIV/TB",
    value: 77139803.22,
    budget: 87262929.14,
    percentage: 88,
  },
  {
    row: "Coordinating Assembly of Non Governmental Organisation",
    parentRow: "Civil Society Organization",
    column: "HIV/TB",
    value: 8708337.27,
    budget: 11114124.21,
    percentage: 78,
  },
  {
    row: "Ministry of Health and Social Assistance of the Republic of Guatemala",
    parentRow: "Governmental Organization",
    column: "Tuberculosis",
    value: 5735953.08,
    budget: 8625860.57,
    percentage: 66,
  },
  {
    row: "Cameroon National Association for Family Welfare",
    parentRow: "Civil Society Organization",
    column: "HIV/TB",
    value: 10127196.06,
    budget: 10781243.24,
    percentage: 94,
  },
  {
    row: "Department of Economic Affairs, Ministry of Finance of India",
    parentRow: "Governmental Organization",
    column: "Malaria",
    value: 72121374.89,
    budget: 107930887.65,
    percentage: 67,
  },
  {
    row: "Office National de la Famille et de la Population de la République de Tunisie",
    parentRow: "Governmental Organization",
    column: "HIV/AIDS",
    value: 7822666.03,
    budget: 9954023.78,
    percentage: 79,
  },
  {
    row: "National Tuberculosis Programme of the Republic of Benin",
    parentRow: "Governmental Organization",
    column: "Tuberculosis",
    value: 15901808.92,
    budget: 22377818.69,
    percentage: 71,
  },
  {
    row: "Community Development Fund",
    parentRow: "Civil Society Organization",
    column: "HIV/TB",
    value: 1054105.87,
    budget: 1380239.38,
    percentage: 76,
  },
  {
    row: "National Department of Health of the Republic of South Africa",
    parentRow: "Governmental Organization",
    column: "HIV/TB",
    value: 229579082.92,
    budget: 286293943.7,
    percentage: 80,
  },
  {
    row: "Ministry of Public Health of the Republic of Madagascar",
    parentRow: "Governmental Organization",
    column: "Malaria",
    value: 9803201.07,
    budget: 12454692.02,
    percentage: 79,
  },
  {
    row: "The Ministry of Health of the Republic of Guinea",
    parentRow: "Governmental Organization",
    column: "HIV/AIDS",
    value: 76503223.24,
    budget: 87127943.38,
    percentage: 88,
  },
  {
    row: "National Treasury of the Republic of Kenya",
    parentRow: "Governmental Organization",
    column: "HIV/AIDS",
    value: 265779431.19,
    budget: 331437003.79,
    percentage: 80,
  },
  {
    row: "Ministry of Public Health and Fight against AIDS of the Republic of Burundi",
    parentRow: "Governmental Organization",
    column: "RSSH",
    value: 2374123,
    budget: 7661545.16,
    percentage: 31,
  },
  {
    row: "Plan International (India Chapter)",
    parentRow: "Civil Society Organization",
    column: "HIV/TB",
    value: 14678969.26,
    budget: 22408888.39,
    percentage: 66,
  },
  {
    row: "Directorate General of Disease Prevention and Control, Ministry of Health of The Republic of Indonesia",
    parentRow: "Governmental Organization",
    column: "HIV/AIDS",
    value: 73353380.47,
    budget: 136398203.39,
    percentage: 54,
  },
  {
    row: "Fundação para o Desenvolvimento da Comunidade",
    parentRow: "Civil Society Organization",
    column: "HIV/AIDS",
    value: 90485992.05,
    budget: 107414513.25,
    percentage: 84,
  },
  {
    row: "International Charitable Foundation “Alliance for Public Health”",
    parentRow: "Civil Society Organization",
    column: "HIV/TB",
    value: 84230125.92,
    budget: 99716225.5,
    percentage: 84,
  },
  {
    row: "Ministry of Health and Social Assistance of the Republic of Guatemala",
    parentRow: "Governmental Organization",
    column: "Malaria",
    value: 5222041.28,
    budget: 6417249.18,
    percentage: 81,
  },
  {
    row: "Ministry of Health of the Kingdom of Morocco",
    parentRow: "Governmental Organization",
    column: "Tuberculosis",
    value: 1847027.71,
    budget: 2099998.52,
    percentage: 88,
  },
  {
    row: "Ministry of Health of the Royal Government of Bhutan",
    parentRow: "Governmental Organization",
    column: "HIV/TB",
    value: 2218380,
    budget: 2942855.5,
    percentage: 75,
  },
  {
    row: "National HIV/AIDS Secretariat",
    parentRow: "Governmental Organization",
    column: "HIV/AIDS",
    value: 32972645.31,
    budget: 33993381.21,
    percentage: 97,
  },
  {
    row: "Alliance Nationale des Communautés pour la Santé - Sénégal",
    parentRow: "Governmental Organization",
    column: "HIV/AIDS",
    value: 12998031.9,
    budget: 14592350.77,
    percentage: 89,
  },
  {
    row: "Ministry of Health of the Republic of Ghana",
    parentRow: "Governmental Organization",
    column: "HIV/TB",
    value: 163031532.71,
    budget: 199458647.18,
    percentage: 82,
  },
  {
    row: "Amref Health Africa",
    parentRow: "Civil Society Organization",
    column: "Malaria",
    value: 23289508.26,
    budget: 33606244.28,
    percentage: 69,
  },
  {
    row: "Federal Ministry of Health of the Republic of Sudan",
    parentRow: "Governmental Organization",
    column: "Malaria",
    value: 211785965.11,
    budget: 231825122.19,
    percentage: 91,
  },
  {
    row: "Directorate General of Disease Prevention and Control, Ministry of Health of The Republic of Indonesia",
    parentRow: "Governmental Organization",
    column: "Tuberculosis",
    value: 191167330.85,
    budget: 302438872.95,
    percentage: 63,
  },
  {
    row: "Association pour Résilience des Communautés vers l’Accès au Développement et à la Santé Plus",
    parentRow: "Civil Society Organization",
    column: "HIV/TB",
    value: 19549832.91,
    budget: 24160809.33,
    percentage: 81,
  },
  {
    row: "Ministry of Health, Public Hygiene and Universal Health Coverage of the Republic of Côte d’Ivoire",
    parentRow: "Governmental Organization",
    column: "Malaria",
    value: 152649232.4,
    budget: 174550807.33,
    percentage: 87,
  },
  {
    row: "Social Security Board",
    parentRow: "Governmental Organization",
    column: "HIV/AIDS",
    value: 763603.99,
    budget: 1228289.61,
    percentage: 62,
  },
  {
    row: "Ministry of Health of the Republic of El Salvador",
    parentRow: "Governmental Organization",
    column: "Tuberculosis",
    value: 4242741.22,
    budget: 4242741.22,
    percentage: 100,
  },
  {
    row: "Secrétariat Permanent du Conseil National de Lutte contre le Sida et les Infections Sexuellement Transmissibles (SP/CNLS-IST)",
    parentRow: "Governmental Organization",
    column: "HIV/AIDS",
    value: 59111717.09,
    budget: 64393342.31,
    percentage: 92,
  },
  {
    row: "Kenya Red Cross Society",
    parentRow: "Civil Society Organization",
    column: "HIV/AIDS",
    value: 130630693.73,
    budget: 145515440.37,
    percentage: 90,
  },
  {
    row: "Ministry of Public Health of the Republic of Cameroon",
    parentRow: "Governmental Organization",
    column: "Malaria",
    value: 150518516.85,
    budget: 174425660.28,
    percentage: 86,
  },
  {
    row: "Empresa Nacional Promotora del Desarrollo Territorial",
    parentRow: "Governmental Organization",
    column: "HIV/AIDS",
    value: 14047464.92,
    budget: 19856046.05,
    percentage: 71,
  },
  {
    row: "Ministry of Health of Sao Tome and Principe",
    parentRow: "Governmental Organization",
    column: "Multi-Component",
    value: 8955656.76,
    budget: 14063746.56,
    percentage: 64,
  },
  {
    row: "Ministry of Health, Public Hygiene and Universal Health Coverage of the Republic of Côte d’Ivoire",
    parentRow: "Governmental Organization",
    column: "RSSH",
    value: 22200338.61,
    budget: 58822027.37,
    percentage: 38,
  },
  {
    row: "Ministry of Public Health of the Republic of Cameroon",
    parentRow: "Governmental Organization",
    column: "Tuberculosis",
    value: 20044989.94,
    budget: 28139192.19,
    percentage: 71,
  },
  {
    row: "Community Development Fund",
    parentRow: "Civil Society Organization",
    column: "Tuberculosis",
    value: 1775001.91,
    budget: 1829246.19,
    percentage: 97,
  },
  {
    row: "Ministry of Health of Republic of Serbia",
    parentRow: "Governmental Organization",
    column: "HIV/AIDS",
    value: 2259528.59,
    budget: 2634418.64,
    percentage: 86,
  },
  {
    row: "Ministry of Health and Social Action of the Republic of Senegal",
    parentRow: "Governmental Organization",
    column: "Malaria",
    value: 30166059.36,
    budget: 36315574.5,
    percentage: 83,
  },
  {
    row: "Ghana-West Africa Program to Combat AIDS - STI",
    parentRow: "Civil Society Organization",
    column: "HIV/AIDS",
    value: 25632481.77,
    budget: 25793212.21,
    percentage: 99,
  },
  {
    row: "Wits Health Consortium (Pty) Ltd",
    parentRow: "Private Sector",
    column: "Tuberculosis",
    value: 19116372.31,
    budget: 22499999.99,
    percentage: 85,
  },
  {
    row: "Republican Scientific and Practical Center for Medical Technologies, Informatization, Administration and Management of Health",
    parentRow: "Governmental Organization",
    column: "HIV/TB",
    value: 29547845.34,
    budget: 34609376.63,
    percentage: 85,
  },
  {
    row: "Institute of Human Virology Nigeria",
    parentRow: "Civil Society Organization",
    column: "Tuberculosis",
    value: 85408183.42,
    budget: 102096278.72,
    percentage: 84,
  },
  {
    row: "Primature de la République Togolaise",
    parentRow: "Governmental Organization",
    column: "Tuberculosis",
    value: 5098126.9,
    budget: 6161495.48,
    percentage: 83,
  },
  {
    row: "Programme National de Lutte contre le Paludisme de la République du Bénin",
    parentRow: "Governmental Organization",
    column: "Malaria",
    value: 72883979.98,
    budget: 81925008.99,
    percentage: 89,
  },
  {
    row: "Ministry of Health of the Republic of El Salvador",
    parentRow: "Governmental Organization",
    column: "HIV/TB",
    value: 1271983.1,
    budget: 12637692.06,
    percentage: 10,
  },
  {
    row: "Ministry of Health of the Republic of Angola",
    parentRow: "Governmental Organization",
    column: "Tuberculosis",
    value: 4784624.42,
    budget: 4403700.27,
    percentage: 109,
  },
  {
    row: "Australian Federation Of AIDS Organisations Limited",
    parentRow: "Governmental Organization",
    column: "HIV/AIDS",
    value: 13935630.26,
    budget: 16075816.13,
    percentage: 87,
  },
  {
    row: "Ministry of Public Health of the Republic of Cameroon",
    parentRow: "Governmental Organization",
    column: "HIV/AIDS",
    value: 221278498.9,
    budget: 275944465.13,
    percentage: 80,
  },
  {
    row: "Ministry of Health, Public Hygiene and Universal Health Coverage of the Republic of Côte d’Ivoire",
    parentRow: "Governmental Organization",
    column: "HIV/AIDS",
    value: 82934138.3,
    budget: 109435853.73,
    percentage: 76,
  },
  {
    row: "Department of Economic Affairs, Ministry of Finance of India",
    parentRow: "Governmental Organization",
    column: "HIV/AIDS",
    value: 127321163.54,
    budget: 136363986,
    percentage: 93,
  },
  {
    row: "Federal Ministry of Health of the Federal Democratic Republic of Ethiopia",
    parentRow: "Governmental Organization",
    column: "Malaria",
    value: 176056708.42,
    budget: 173148804.95,
    percentage: 102,
  },
  {
    row: "Inter-American Development Bank",
    parentRow: "Multilateral Organization",
    column: "Malaria",
    value: 262627.67,
    budget: 10953956.15,
    percentage: 2,
  },
  {
    row: "Economic Relations Division, Ministry of Finance of the People's Republic of Bangladesh",
    parentRow: "Governmental Organization",
    column: "HIV/AIDS",
    value: 2556417.01,
    budget: 3754211,
    percentage: 68,
  },
  {
    row: "Humanist Institute for Co-operation with Developing Countries",
    parentRow: "Civil Society Organization",
    column: "HIV/AIDS",
    value: 4125030.78,
    budget: 4909342.25,
    percentage: 84,
  },
  {
    row: "National TB Control Programme Pakistan",
    parentRow: "Governmental Organization",
    column: "Tuberculosis",
    value: 171271255.03,
    budget: 240465407.09,
    percentage: 71,
  },
  {
    row: "Cellule Nationale de Coordination Technique de la Riposte Nationale au Sida et aux Hépatites",
    parentRow: "Governmental Organization",
    column: "HIV/AIDS",
    value: 14696188.87,
    budget: 15939092.26,
    percentage: 92,
  },
  {
    row: "Ministry of Health of the Republic of Suriname",
    parentRow: "Governmental Organization",
    column: "HIV/TB",
    value: 1857705.2,
    budget: 2201759.96,
    percentage: 84,
  },
  {
    row: "Ministry of Health of the Republic of Armenia",
    parentRow: "Governmental Organization",
    column: "HIV/TB",
    value: 17275226.98,
    budget: 17775387.21,
    percentage: 97,
  },
  {
    row: "BRAC",
    parentRow: "Civil Society Organization",
    column: "Malaria",
    value: 25018124.81,
    budget: 26865627.44,
    percentage: 93,
  },
  {
    row: "Cooperative Housing Foundation",
    parentRow: "Civil Society Organization",
    column: "HIV/TB",
    value: 14296293.12,
    budget: 25158423.31,
    percentage: 57,
  },
  {
    row: "Catholic Relief Services - United States Conference of Catholic Bishops",
    parentRow: "Civil Society Organization",
    column: "Tuberculosis",
    value: 17572697.14,
    budget: 24935971.81,
    percentage: 70,
  },
  {
    row: "Ministry of Health and Social Development of the Republic of Mali",
    parentRow: "Governmental Organization",
    column: "HIV/TB",
    value: 25444879.5,
    budget: 49220967.03,
    percentage: 52,
  },
  {
    row: "International Organization for Migration",
    parentRow: "Multilateral Organization",
    column: "Multi-Component",
    value: 77882876.5,
    budget: 110803876.31,
    percentage: 70,
  },
  {
    row: "Ministry of Public Health and Prevention",
    parentRow: "Governmental Organization",
    column: "HIV/TB",
    value: 15104166.74,
    budget: 36284482.33,
    percentage: 42,
  },
  {
    row: "BRAC",
    parentRow: "Civil Society Organization",
    column: "Tuberculosis",
    value: 125910576.08,
    budget: 143232602.53,
    percentage: 88,
  },
  {
    row: "William J. Clinton Foundation",
    parentRow: "Private Sector",
    column: "Tuberculosis",
    value: 13414067.87,
    budget: 19765397.61,
    percentage: 68,
  },
  {
    row: "Socios en Salud sucursal Peru",
    parentRow: "Civil Society Organization",
    column: "HIV/TB",
    value: 3412055.79,
    budget: 2987886.98,
    percentage: 114,
  },
  {
    row: "Consejo Nacional para el VIH y el SIDA",
    parentRow: "Governmental Organization",
    column: "HIV/AIDS",
    value: 13151647.48,
    budget: 17731044.85,
    percentage: 74,
  },
  {
    row: "Ministry of Finance of the Kingdom of Lesotho",
    parentRow: "Governmental Organization",
    column: "HIV/TB",
    value: 97128652.44,
    budget: 144470849.83,
    percentage: 67,
  },
  {
    row: "National Emergency Response Council on HIV and AIDS",
    parentRow: "Governmental Organization",
    column: "HIV/TB",
    value: 57124767.83,
    budget: 101666698.84,
    percentage: 56,
  },
  {
    row: "Primature de la République Togolaise",
    parentRow: "Governmental Organization",
    column: "HIV/AIDS",
    value: 72068444.58,
    budget: 99257780.49,
    percentage: 73,
  },
  {
    row: "World Vision (PNG) Trust",
    parentRow: "Civil Society Organization",
    column: "HIV/TB",
    value: 70517714.44,
    budget: 90086371.58,
    percentage: 78,
  },
  {
    row: "Ministry of Health of the Republic of Rwanda",
    parentRow: "Governmental Organization",
    column: "Malaria",
    value: 118567222.23,
    budget: 120343744.33,
    percentage: 99,
  },
  {
    row: "National Center For Disease Control and Public Health",
    parentRow: "Governmental Organization",
    column: "HIV/AIDS",
    value: 10345025.91,
    budget: 12265386.1,
    percentage: 84,
  },
  {
    row: "Persatuan Karya Dharma Kesehatan Indonesia (also known as “PERDHAKI”, Association of Voluntary Health Services of Indonesia)",
    parentRow: "Civil Society Organization",
    column: "Malaria",
    value: 17259339.21,
    budget: 24371088.97,
    percentage: 71,
  },
  {
    row: "Indus Hospital & Health Network",
    parentRow: "Private Sector",
    column: "Malaria",
    value: 18452659.53,
    budget: 27248225.94,
    percentage: 68,
  },
  {
    row: "Organismo Andino de Salud - Convenio Hipólito Unanue",
    parentRow: "Civil Society Organization",
    column: "Tuberculosis",
    value: 3605124.87,
    budget: 4600000,
    percentage: 78,
  },
  {
    row: "Programme d'Appui au Développement Sanitaire (PADS)",
    parentRow: "Governmental Organization",
    column: "Malaria",
    value: 298601680.85,
    budget: 337126779.66,
    percentage: 89,
  },
  {
    row: "Ministry of Health of Mongolia",
    parentRow: "Governmental Organization",
    column: "HIV/AIDS",
    value: 2880092.08,
    budget: 3044707.77,
    percentage: 95,
  },
  {
    row: "Ministry of Health of the Republic of Azerbaijan",
    parentRow: "Governmental Organization",
    column: "HIV/TB",
    value: 8920836.24,
    budget: 13456798.6,
    percentage: 66,
  },
  {
    row: "Instituto Dermatológico y Cirugía de Piel ‘Dr. Huberto Bogaert Díaz’",
    parentRow: "Civil Society Organization",
    column: "HIV/AIDS",
    value: 12533754.66,
    budget: 13442869.04,
    percentage: 93,
  },
  {
    row: "Ministry of Health of the Republic of Rwanda",
    parentRow: "Governmental Organization",
    column: "HIV/TB",
    value: 113232497.81,
    budget: 148463394.44,
    percentage: 76,
  },
  {
    row: "Primature de la République Togolaise",
    parentRow: "Governmental Organization",
    column: "Malaria",
    value: 72565783.65,
    budget: 73553690.95,
    percentage: 99,
  },
  {
    row: "Ministry of Finance of the United Republic of Tanzania",
    parentRow: "Governmental Organization",
    column: "HIV/AIDS",
    value: 532167847,
    budget: 609230837.02,
    percentage: 87,
  },
  {
    row: "Sécrétariat Exécutif du Comité National de Lutte Contre le VIH/SIDA de la République de Madagascar",
    parentRow: "Governmental Organization",
    column: "HIV/AIDS",
    value: 15093242.94,
    budget: 19483646.59,
    percentage: 77,
  },
  {
    row: "Ministry of National Health Services, Regulations and Coordination of Pakistan",
    parentRow: "Governmental Organization",
    column: "HIV/AIDS",
    value: 17481026.85,
    budget: 22241118.86,
    percentage: 79,
  },
  {
    row: "Instituto Nicaragüense de Seguridad Social",
    parentRow: "Governmental Organization",
    column: "Tuberculosis",
    value: 527259.7,
    budget: 553831.9,
    percentage: 95,
  },
  {
    row: "Public Institution - Coordination, Implementation and Monitoring Unit of the Health  System Projects",
    parentRow: "Governmental Organization",
    column: "HIV/TB",
    value: 27215521.32,
    budget: 34776852.84,
    percentage: 78,
  },
  {
    row: "Pilipinas Shell Foundation, Inc.",
    parentRow: "Private Sector",
    column: "HIV/AIDS",
    value: 18185138,
    budget: 20732077.39,
    percentage: 88,
  },
  {
    row: "Ministry of Finance, Planning and Economic Development of the Republic of Uganda",
    parentRow: "Governmental Organization",
    column: "Malaria",
    value: 260157129.93,
    budget: 287054209.15,
    percentage: 91,
  },
  {
    row: "Ministry of Health of the Republic of Ghana",
    parentRow: "Governmental Organization",
    column: "Malaria",
    value: 188361725.99,
    budget: 187781246.21,
    percentage: 100,
  },
  {
    row: "Ministry of Health of the Royal Government of Bhutan",
    parentRow: "Governmental Organization",
    column: "Malaria",
    value: 2940266.1,
    budget: 3117827.06,
    percentage: 94,
  },
  {
    row: "United Nations Office for Project Services",
    parentRow: "Multilateral Organization",
    column: "Malaria",
    value: 416394284.77,
    budget: 537632140.88,
    percentage: 77,
  },
  {
    row: "Ministry of Health, Solidarity, Social Protection and Gender Promotion of the Union of the Comoros",
    parentRow: "Governmental Organization",
    column: "Malaria",
    value: 6320458.47,
    budget: 6781346.65,
    percentage: 93,
  },
  {
    row: "United Nations Children's Fund",
    parentRow: "Multilateral Organization",
    column: "Multi-Component",
    value: 11125489.28,
    budget: 43409436.94,
    percentage: 26,
  },
  {
    row: "Ministry of Health of the Lao People's Democratic Republic",
    parentRow: "Governmental Organization",
    column: "Tuberculosis",
    value: 8024149.8,
    budget: 8647016.79,
    percentage: 93,
  },
  {
    row: "Ministry of Health of the Lao People's Democratic Republic",
    parentRow: "Governmental Organization",
    column: "HIV/TB",
    value: 11243171.64,
    budget: 19489616.79,
    percentage: 58,
  },
  {
    row: "Raks Thai Foundation",
    parentRow: "Civil Society Organization",
    column: "HIV/TB",
    value: 44586586.13,
    budget: 49389231.77,
    percentage: 90,
  },
  {
    row: "Ministry of Health of the Republic of Rwanda",
    parentRow: "Governmental Organization",
    column: "HIV/AIDS",
    value: 173695669.95,
    budget: 180056518.24,
    percentage: 96,
  },
  {
    row: "Ministry of Health of Mongolia",
    parentRow: "Governmental Organization",
    column: "Tuberculosis",
    value: 9037799.35,
    budget: 10037181.09,
    percentage: 90,
  },
  {
    row: "United Nations Development Programme",
    parentRow: "Multilateral Organization",
    column: "HIV/AIDS",
    value: 902756371.41,
    budget: 1086530937.29,
    percentage: 83,
  },
  {
    row: "United Nations Development Programme",
    parentRow: "Multilateral Organization",
    column: "Multi-Component",
    value: 148672479.92,
    budget: 193680922.71,
    percentage: 77,
  },
  {
    row: "World Vision International",
    parentRow: "Civil Society Organization",
    column: "Tuberculosis",
    value: 51090733.13,
    budget: 55586723.06,
    percentage: 92,
  },
  {
    row: "India HIV/AIDS Alliance",
    parentRow: "Civil Society Organization",
    column: "HIV/AIDS",
    value: 43040443.67,
    budget: 57284090.27,
    percentage: 75,
  },
  {
    row: "Ministry of Health and Wellness",
    parentRow: "Governmental Organization",
    column: "HIV/AIDS",
    value: 2081390.28,
    budget: 3478044.25,
    percentage: 60,
  },
  {
    row: "Solomon Islands Ministry of Health and Medical Services",
    parentRow: "Governmental Organization",
    column: "Tuberculosis",
    value: 817719.89,
    budget: 864458.66,
    percentage: 95,
  },
  {
    row: "Viet Nam Union of Science and Technology Associations",
    parentRow: "Civil Society Organization",
    column: "HIV/AIDS",
    value: 12386196.28,
    budget: 12798919.1,
    percentage: 97,
  },
  {
    row: "Alliance Nationale pour la Santé et le Développement en Côte D'Ivoire",
    parentRow: "Civil Society Organization",
    column: "HIV/TB",
    value: 28470511.24,
    budget: 39653669.83,
    percentage: 72,
  },
  {
    row: "Save the Children Federation, Inc.",
    parentRow: "Civil Society Organization",
    column: "Tuberculosis",
    value: 62307739.25,
    budget: 68123243.57,
    percentage: 91,
  },
  {
    row: "Ministry of Health of the Revolutionary Government of Zanzibar",
    parentRow: "Governmental Organization",
    column: "Malaria",
    value: 9977411.1,
    budget: 11842093.91,
    percentage: 84,
  },
  {
    row: "United Nations Office for Project Services",
    parentRow: "Multilateral Organization",
    column: "Tuberculosis",
    value: 125894804.09,
    budget: 153634422.1,
    percentage: 82,
  },
  {
    row: "The Family Planning Association of Sri Lanka",
    parentRow: "Civil Society Organization",
    column: "HIV/AIDS",
    value: 2878694,
    budget: 3545721.22,
    percentage: 81,
  },
  {
    row: "National High Council for the Fight against AIDS",
    parentRow: "Governmental Organization",
    column: "HIV/AIDS",
    value: 50880988.68,
    budget: 53029811.43,
    percentage: 96,
  },
  {
    row: "Management Sciences for Health",
    parentRow: "Civil Society Organization",
    column: "RSSH",
    value: 35875534,
    budget: 42996203.21,
    percentage: 83,
  },
  {
    row: "Ministry of Health and Social Services of Namibia",
    parentRow: "Governmental Organization",
    column: "Malaria",
    value: 2809325.1,
    budget: 3070580.61,
    percentage: 91,
  },
  {
    row: "Ministry of Health and Social Protection of the Republic of Albania",
    parentRow: "Governmental Organization",
    column: "HIV/TB",
    value: 2418683,
    budget: 2868559.78,
    percentage: 84,
  },
  {
    row: "Coordination Committee of the Fight against AIDS of Cabo Verde",
    parentRow: "Governmental Organization",
    column: "Multi-Component",
    value: 8723215.08,
    budget: 10108514.28,
    percentage: 86,
  },
  {
    row: 'Charitable Organization "All-Ukrainian Network of People Living with HIV/AIDS"',
    parentRow: "Civil Society Organization",
    column: "HIV/TB",
    value: 85058972.32,
    budget: 100115573.35,
    percentage: 85,
  },
  {
    row: "Pilipinas Shell Foundation, Inc.",
    parentRow: "Private Sector",
    column: "Malaria",
    value: 17164428.92,
    budget: 19688293.51,
    percentage: 87,
  },
  {
    row: "Centre de Recherches Médicales de Lambaréné",
    parentRow: "Civil Society Organization",
    column: "Tuberculosis",
    value: 2780588.05,
    budget: 4134573.95,
    percentage: 67,
  },
  {
    row: "Centro de Información y Recursos para el Desarrollo",
    parentRow: "Civil Society Organization",
    column: "HIV/AIDS",
    value: 8108346.21,
    budget: 10465888.39,
    percentage: 77,
  },
  {
    row: "SANRU Asbl",
    parentRow: "Civil Society Organization",
    column: "Malaria",
    value: 629697460.26,
    budget: 740141500.71,
    percentage: 85,
  },
  {
    row: "Namibia Network of AIDS Service Organizations",
    parentRow: "Civil Society Organization",
    column: "HIV/TB",
    value: 3548526.88,
    budget: 3782407.83,
    percentage: 94,
  },
  {
    row: "Solomon Islands Ministry of Health and Medical Services",
    parentRow: "Governmental Organization",
    column: "HIV/TB",
    value: 1200803.9,
    budget: 1512241.2,
    percentage: 79,
  },
  {
    row: "T.C.I. Foundation",
    parentRow: "Civil Society Organization",
    column: "Malaria",
    value: 2074097.54,
    budget: 10743330.83,
    percentage: 19,
  },
  {
    row: "Ministry of Health of the Republic of Malawi",
    parentRow: "Governmental Organization",
    column: "HIV/TB",
    value: 621757292.87,
    budget: 648811696.19,
    percentage: 96,
  },
  {
    row: "Centre for Health Research and Innovation",
    parentRow: "Civil Society Organization",
    column: "Tuberculosis",
    value: 10991249.98,
    budget: 15596592,
    percentage: 70,
  },
  {
    row: "Ministry of Health of the Republic of El Salvador",
    parentRow: "Governmental Organization",
    column: "HIV/AIDS",
    value: 14300380,
    budget: 21230477.25,
    percentage: 67,
  },
  {
    row: "Federal Ministry of Health of the Federal Democratic Republic of Ethiopia",
    parentRow: "Governmental Organization",
    column: "Tuberculosis",
    value: 82095822.89,
    budget: 89712392.7,
    percentage: 92,
  },
  {
    row: "Lagos State Ministry of Health",
    parentRow: "Governmental Organization",
    column: "HIV/TB",
    value: 15320397.81,
    budget: 20207233.62,
    percentage: 76,
  },
  {
    row: "World Vision International",
    parentRow: "Civil Society Organization",
    column: "Malaria",
    value: 177473352.23,
    budget: 209541750.63,
    percentage: 85,
  },
  {
    row: "World Vision Malawi",
    parentRow: "Civil Society Organization",
    column: "Malaria",
    value: 61327397,
    budget: 63459117.13,
    percentage: 97,
  },
  {
    row: "World Vision International",
    parentRow: "Civil Society Organization",
    column: "HIV/AIDS",
    value: 6593014.27,
    budget: 7551955.46,
    percentage: 87,
  },
  {
    row: 'State Institution "Public Health Center of the Ministry of Health of Ukraine"',
    parentRow: "Governmental Organization",
    column: "HIV/TB",
    value: 93458574.52,
    budget: 119390993.28,
    percentage: 78,
  },
  {
    row: "ActionAid International Malawi",
    parentRow: "Civil Society Organization",
    column: "HIV/TB",
    value: 29244399,
    budget: 30082198.12,
    percentage: 97,
  },
  {
    row: "Ministry of Health, Solidarity, Social Protection and Gender Promotion of the Union of the Comoros",
    parentRow: "Governmental Organization",
    column: "HIV/TB",
    value: 818070.77,
    budget: 2186441.95,
    percentage: 37,
  },
  {
    row: "Ministry of Finance, Planning and Economic Development of the Republic of Uganda",
    parentRow: "Governmental Organization",
    column: "HIV/AIDS",
    value: 479281353.41,
    budget: 611972079.58,
    percentage: 78,
  },
  {
    row: "Ministry of Health of the Republic of Suriname",
    parentRow: "Governmental Organization",
    column: "HIV/AIDS",
    value: 338492.6,
    budget: 955768.85,
    percentage: 35,
  },
  {
    row: "Ministry of Health of the Republic of Malawi",
    parentRow: "Governmental Organization",
    column: "Malaria",
    value: 132902471,
    budget: 204718078.09,
    percentage: 65,
  },
  {
    row: "Republican DOTS Centre  Ministry of Health of the Republic of Uzbekistan",
    parentRow: "Governmental Organization",
    column: "Tuberculosis",
    value: 19691331.48,
    budget: 21118257.35,
    percentage: 93,
  },
  {
    row: "International Union Against Tuberculosis and Lung Disease",
    parentRow: "Civil Society Organization",
    column: "Tuberculosis",
    value: 21310264.48,
    budget: 29158862.62,
    percentage: 73,
  },
  {
    row: "William J. Clinton Foundation",
    parentRow: "Private Sector",
    column: "HIV/TB",
    value: 13281339.56,
    budget: 18283889.09,
    percentage: 73,
  },
  {
    row: "Lagos State Ministry of Health",
    parentRow: "Governmental Organization",
    column: "Tuberculosis",
    value: 7600284,
    budget: 9968241.24,
    percentage: 76,
  },
  {
    row: "Socios en Salud sucursal Peru",
    parentRow: "Civil Society Organization",
    column: "Tuberculosis",
    value: 13662272.36,
    budget: 22557156.23,
    percentage: 61,
  },
  {
    row: "National Executive Secretariat for fight against Aids of the Islamic Republic of Mauritania",
    parentRow: "Governmental Organization",
    column: "Multi-Component",
    value: 15462565.91,
    budget: 23765504.47,
    percentage: 65,
  },
  {
    row: "United Nations Children's Fund",
    parentRow: "Multilateral Organization",
    column: "HIV/AIDS",
    value: 32462769.02,
    budget: 39548856.63,
    percentage: 82,
  },
  {
    row: "Ministry of Health of Mongolia",
    parentRow: "Governmental Organization",
    column: "HIV/TB",
    value: 10857730.45,
    budget: 14149274.67,
    percentage: 77,
  },
  {
    row: "Foundation for Innovative New Diagnostics India",
    parentRow: "Civil Society Organization",
    column: "Tuberculosis",
    value: 63645511.75,
    budget: 98938394.2,
    percentage: 64,
  },
  {
    row: "Ministry of Health of the Co-operative Republic of Guyana",
    parentRow: "Governmental Organization",
    column: "HIV/TB",
    value: 742221.63,
    budget: 1410238.62,
    percentage: 53,
  },
  {
    row: "World Vision International",
    parentRow: "Civil Society Organization",
    column: "HIV/TB",
    value: 36230666.33,
    budget: 66286750.32,
    percentage: 55,
  },
  {
    row: "The AIDS Support Organisation (Uganda) Limited",
    parentRow: "Civil Society Organization",
    column: "Malaria",
    value: 37281082.47,
    budget: 43492070.81,
    percentage: 86,
  },
  {
    row: "Cambodia Ministry of Economy and Finance",
    parentRow: "Governmental Organization",
    column: "HIV/TB",
    value: 111551974.63,
    budget: 137096031.36,
    percentage: 81,
  },
  {
    row: "Ministry of Health and Social Welfare",
    parentRow: "Governmental Organization",
    column: "Malaria",
    value: 24766971.62,
    budget: 30017510.22,
    percentage: 83,
  },
  {
    row: "Plan International (India Chapter)",
    parentRow: "Civil Society Organization",
    column: "HIV/AIDS",
    value: 13666770.26,
    budget: 17802600.44,
    percentage: 77,
  },
  {
    row: "Department of Disease Control, Ministry of Public Health of the Royal Government of Thailand",
    parentRow: "Governmental Organization",
    column: "HIV/TB",
    value: 44488956.26,
    budget: 64304239.48,
    percentage: 69,
  },
  {
    row: "Plan International, Inc.",
    parentRow: "Civil Society Organization",
    column: "Malaria",
    value: 24945436.6,
    budget: 29399897.22,
    percentage: 85,
  },
  {
    row: "Indus Hospital & Health Network",
    parentRow: "Private Sector",
    column: "Tuberculosis",
    value: 35040660.73,
    budget: 41180437.77,
    percentage: 85,
  },
  {
    row: "International Bank for Reconstruction and Development",
    parentRow: "Multilateral Organization",
    column: "Tuberculosis",
    value: 41600000,
    budget: 41600000,
    percentage: 100,
  },
  {
    row: "Alliance Nationale pour la Santé et le Développement en Côte D'Ivoire",
    parentRow: "Civil Society Organization",
    column: "Tuberculosis",
    value: 4403508.06,
    budget: 4937717.34,
    percentage: 89,
  },
  {
    row: "Ministry of Health and Social Services of Namibia",
    parentRow: "Governmental Organization",
    column: "Multi-Component",
    value: 33194469,
    budget: 49281286.99,
    percentage: 67,
  },
  {
    row: "Churches Health Association of Zambia",
    parentRow: "Civil Society Organization",
    column: "Malaria",
    value: 37495429,
    budget: 41685040.05,
    percentage: 90,
  },
  {
    row: "Ministry of Health of Mozambique",
    parentRow: "Governmental Organization",
    column: "Tuberculosis",
    value: 60644696.82,
    budget: 92514237.04,
    percentage: 66,
  },
  {
    row: "Philippine Business for Social Progress, Inc.",
    parentRow: "Civil Society Organization",
    column: "Tuberculosis",
    value: 235768510.52,
    budget: 255159655.73,
    percentage: 92,
  },
  {
    row: "Ministry of Health and Social Services of Namibia",
    parentRow: "Governmental Organization",
    column: "HIV/TB",
    value: 31440417.8,
    budget: 36132321.12,
    percentage: 87,
  },
  {
    row: "Ministry of Health of the State of Eritrea",
    parentRow: "Governmental Organization",
    column: "HIV/AIDS",
    value: 32620798.38,
    budget: 41096278.99,
    percentage: 79,
  },
  {
    row: "Ministry of Health of the Democratic Socialist  Republic of Sri Lanka",
    parentRow: "Governmental Organization",
    column: "RSSH",
    value: 581778.42,
    budget: 14848752.59,
    percentage: 4,
  },
  {
    row: "Beyond Zero NPC",
    parentRow: "Civil Society Organization",
    column: "HIV/TB",
    value: 61369861.29,
    budget: 75716004.37,
    percentage: 81,
  },
  {
    row: "Ministry of Health and Social Welfare",
    parentRow: "Governmental Organization",
    column: "HIV/TB",
    value: 45039539.83,
    budget: 66376385.75,
    percentage: 68,
  },
  {
    row: "United Nations Children's Fund",
    parentRow: "Multilateral Organization",
    column: "HIV/TB",
    value: 8862596,
    budget: 15000000,
    percentage: 59,
  },
  {
    row: "African Comprehensive HIV/AIDS Partnerships",
    parentRow: "Multilateral Organization",
    column: "HIV/TB",
    value: 13585191.1,
    budget: 15068680.49,
    percentage: 90,
  },
  {
    row: "The Ministry of Health and Child Care of the Republic of Zimbabwe",
    parentRow: "Governmental Organization",
    column: "Tuberculosis",
    value: 44063023.79,
    budget: 52395325.6,
    percentage: 84,
  },
  {
    row: "Ministry of Health of the Republic of The Gambia",
    parentRow: "Governmental Organization",
    column: "Malaria",
    value: 38775780.6,
    budget: 43414369.31,
    percentage: 89,
  },
  {
    row: "Catholic Relief Services - United States Conference of Catholic Bishops",
    parentRow: "Civil Society Organization",
    column: "Malaria",
    value: 904924277.19,
    budget: 989285703.71,
    percentage: 91,
  },
  {
    row: "Ministry of Public Health, Population and Social Affairs",
    parentRow: "Governmental Organization",
    column: "Tuberculosis",
    value: 26024715.05,
    budget: 31805567.72,
    percentage: 82,
  },
  {
    row: "Community Development Fund",
    parentRow: "Civil Society Organization",
    column: "HIV/AIDS",
    value: 2698230.81,
    budget: 2801151.36,
    percentage: 96,
  },
  {
    row: "Ministry of Finance of the United Republic of Tanzania",
    parentRow: "Governmental Organization",
    column: "Malaria",
    value: 342522439.38,
    budget: 424100453.71,
    percentage: 81,
  },
  {
    row: "Conseil National de Lutte contre le SIDA de la République du Sénégal",
    parentRow: "Governmental Organization",
    column: "HIV/AIDS",
    value: 31713295.49,
    budget: 35654269.69,
    percentage: 89,
  },
  {
    row: "Networking HIV and AIDS Community of Southern Africa NPC",
    parentRow: "Civil Society Organization",
    column: "HIV/TB",
    value: 91276470,
    budget: 130650057.74,
    percentage: 70,
  },
  {
    row: "Mercy Corps",
    parentRow: "Civil Society Organization",
    column: "Tuberculosis",
    value: 44539919.79,
    budget: 53059508.99,
    percentage: 84,
  },
  {
    row: "Ministry of Health of the Co-operative Republic of Guyana",
    parentRow: "Governmental Organization",
    column: "Malaria",
    value: 2451421.96,
    budget: 3370983.25,
    percentage: 73,
  },
  {
    row: "RSE on REU “National Scientific Center of Phthisiopulmonology of the Republic of  Kazakhstan” of the Ministry of Health of the Republic of  Kazakhstan",
    parentRow: "Governmental Organization",
    column: "Tuberculosis",
    value: 14251436.46,
    budget: 14743462.95,
    percentage: 97,
  },
  {
    row: "ActionAid International The Gambia",
    parentRow: "Civil Society Organization",
    column: "HIV/TB",
    value: 4416421.78,
    budget: 5076253.09,
    percentage: 87,
  },
  {
    row: "Ministry of Health of the Royal Government of Bhutan",
    parentRow: "Governmental Organization",
    column: "Tuberculosis",
    value: 1284552.98,
    budget: 1303205.01,
    percentage: 99,
  },
  {
    row: "Family Health International (FHI360)",
    parentRow: "Civil Society Organization",
    column: "HIV/AIDS",
    value: 301181595,
    budget: 369054723.61,
    percentage: 82,
  },
  {
    row: "Directorate of Malaria Control, Ministry of National Health Services, Regulations and Coordination of Pakistan",
    parentRow: "Governmental Organization",
    column: "Malaria",
    value: 66553754.08,
    budget: 62864757.2,
    percentage: 106,
  },
  {
    row: "CARE Peru",
    parentRow: "Civil Society Organization",
    column: "HIV/AIDS",
    value: 5884014.99,
    budget: 6264585.26,
    percentage: 94,
  },
  {
    row: "Frontline AIDS",
    parentRow: "Civil Society Organization",
    column: "HIV/AIDS",
    value: 8319787.92,
    budget: 11414407.85,
    percentage: 73,
  },
  {
    row: "Botswana-Ministry of Health",
    parentRow: "Governmental Organization",
    column: "HIV/TB",
    value: 9986805.01,
    budget: 23705886.8,
    percentage: 42,
  },
  {
    row: "National Agency for the Control of AIDS",
    parentRow: "Governmental Organization",
    column: "RSSH",
    value: 104845375,
    budget: 263149668.72,
    percentage: 40,
  },
  {
    row: "Programme santé de lutte contre le Sida",
    parentRow: "Governmental Organization",
    column: "HIV/AIDS",
    value: 41259357.25,
    budget: 59661663.1,
    percentage: 69,
  },
  {
    row: "Ministry of Public Health and Prevention",
    parentRow: "Governmental Organization",
    column: "Tuberculosis",
    value: 4368736.8,
    budget: 4816414.05,
    percentage: 91,
  },
  {
    row: "Ministry of Public Health of the Republic of Ecuador",
    parentRow: "Governmental Organization",
    column: "HIV/AIDS",
    value: 9008781,
    budget: 9672292.56,
    percentage: 93,
  },
  {
    row: "Office National de Nutrition",
    parentRow: "Governmental Organization",
    column: "Tuberculosis",
    value: 9194255.04,
    budget: 9746071.87,
    percentage: 94,
  },
  {
    row: "United Nations Office for Project Services",
    parentRow: "Multilateral Organization",
    column: "HIV/AIDS",
    value: 126545358.66,
    budget: 154657637.35,
    percentage: 82,
  },
  {
    row: "Ministry of Health of the Co-operative Republic of Guyana",
    parentRow: "Governmental Organization",
    column: "Tuberculosis",
    value: 310824.2,
    budget: 458284.24,
    percentage: 68,
  },
  {
    row: "The Rotary Club of Port Moresby Inc.",
    parentRow: "Private Sector",
    column: "Malaria",
    value: 59928581.53,
    budget: 62201792.45,
    percentage: 96,
  },
  {
    row: "Ministry of Health and Social Action of the Republic of Senegal",
    parentRow: "Governmental Organization",
    column: "Multi-Component",
    value: 48100775.56,
    budget: 78555774.74,
    percentage: 61,
  },
  {
    row: "Viet Nam Authority of HIV/AIDS Control",
    parentRow: "Governmental Organization",
    column: "HIV/AIDS",
    value: 87436909,
    budget: 107390045.86,
    percentage: 81,
  },
  {
    row: "Ministry of Health of the Kingdom of Morocco",
    parentRow: "Governmental Organization",
    column: "HIV/AIDS",
    value: 12417422.67,
    budget: 13282254.66,
    percentage: 93,
  },
  {
    row: "Ministry of Health, Public Hygiene and Universal Health Coverage of the Republic of Côte d’Ivoire",
    parentRow: "Governmental Organization",
    column: "Tuberculosis",
    value: 16443746.46,
    budget: 25481649.72,
    percentage: 65,
  },
  {
    row: "Churches Health Association of Zambia",
    parentRow: "Civil Society Organization",
    column: "HIV/TB",
    value: 134725452.35,
    budget: 163867471.4,
    percentage: 82,
  },
  {
    row: "National Treasury of the Republic of Kenya",
    parentRow: "Governmental Organization",
    column: "Malaria",
    value: 82074805.01,
    budget: 97043234.41,
    percentage: 85,
  },
  {
    row: "Yayasan Spiritia",
    parentRow: "Civil Society Organization",
    column: "HIV/AIDS",
    value: 49628497.65,
    budget: 55405091.22,
    percentage: 90,
  },
  {
    row: "Botswana-Ministry of Health",
    parentRow: "Governmental Organization",
    column: "Malaria",
    value: 538616.95,
    budget: 1287500,
    percentage: 42,
  },
  {
    row: "National Treasury of the Republic of Kenya",
    parentRow: "Governmental Organization",
    column: "Tuberculosis",
    value: 68251642.26,
    budget: 103403669.58,
    percentage: 66,
  },
  {
    row: "Organisation of Eastern Caribbean States",
    parentRow: "Civil Society Organization",
    column: "HIV/TB",
    value: 4419361.71,
    budget: 5140467.73,
    percentage: 86,
  },
  {
    row: "Center for Health Policies and Studies",
    parentRow: "Civil Society Organization",
    column: "Tuberculosis",
    value: 10090048.38,
    budget: 10193463.53,
    percentage: 99,
  },
  {
    row: "La Croix-Rouge française",
    parentRow: "Civil Society Organization",
    column: "HIV/TB",
    value: 116927751.51,
    budget: 142183137.76,
    percentage: 82,
  },
  {
    row: "Ministry of Health of the Republic of Azerbaijan",
    parentRow: "Governmental Organization",
    column: "HIV/AIDS",
    value: 7100810.95,
    budget: 7176227.73,
    percentage: 99,
  },
  {
    row: "Indonesia AIDS Coalition",
    parentRow: "Governmental Organization",
    column: "HIV/AIDS",
    value: 7574614,
    budget: 10781867.96,
    percentage: 70,
  },
  {
    row: "International Organization for Migration",
    parentRow: "Multilateral Organization",
    column: "Tuberculosis",
    value: 808607.5,
    budget: 3655672.63,
    percentage: 22,
  },
  {
    row: "Ministry of Public Health of the Islamic Republic of Afghanistan",
    parentRow: "Governmental Organization",
    column: "Tuberculosis",
    value: 9243997.68,
    budget: 10523057.58,
    percentage: 88,
  },
  {
    row: "Ministry of Health of the Kingdom of Morocco",
    parentRow: "Governmental Organization",
    column: "HIV/TB",
    value: 16011014.69,
    budget: 19100454.27,
    percentage: 84,
  },
  {
    row: "Viet Nam National Lung Hospital",
    parentRow: "Governmental Organization",
    column: "Tuberculosis",
    value: 91260523.66,
    budget: 138955198.95,
    percentage: 66,
  },
  {
    row: "Malaysian AIDS Council",
    parentRow: "Governmental Organization",
    column: "HIV/AIDS",
    value: 4007177.35,
    budget: 5272821.12,
    percentage: 76,
  },
  {
    row: "Ministry of Health of the Republic of Zambia",
    parentRow: "Governmental Organization",
    column: "Malaria",
    value: 114514133,
    budget: 121165123.1,
    percentage: 95,
  },
  {
    row: "Conseil National de Lutte contre le VIH/SIDA, la Tuberculose, le Paludisme, les Hépatites, les Infections sexuellement transmissibles et les Epidémies",
    parentRow: "Governmental Organization",
    column: "RSSH",
    value: 9489505.37,
    budget: 26659590.77,
    percentage: 36,
  },
  {
    row: "United Nations Children's Fund",
    parentRow: "Multilateral Organization",
    column: "Malaria",
    value: 98259639.98,
    budget: 135344598.4,
    percentage: 73,
  },
  {
    row: "Ministry of Public Health, Hygiene and Prevention",
    parentRow: "Governmental Organization",
    column: "Tuberculosis",
    value: 28270271,
    budget: 33018627.29,
    percentage: 86,
  },
  {
    row: "Directorate General of Disease Prevention and Control, Ministry of Health of The Republic of Indonesia",
    parentRow: "Governmental Organization",
    column: "Malaria",
    value: 65816415.83,
    budget: 80482383.89,
    percentage: 82,
  },
  {
    row: "Republican Specialized Scientific-Practical Medical Center of Phthisiology and Pulmonology",
    parentRow: "Governmental Organization",
    column: "Tuberculosis",
    value: 386029.09,
    budget: 841212.1,
    percentage: 46,
  },
  {
    row: "Ministry of Health of the Co-operative Republic of Guyana",
    parentRow: "Governmental Organization",
    column: "HIV/AIDS",
    value: 2409770.87,
    budget: 4539733.79,
    percentage: 53,
  },
  {
    row: "Ministry of Finance of the United Republic of Tanzania",
    parentRow: "Governmental Organization",
    column: "Tuberculosis",
    value: 60744700.51,
    budget: 85600992.11,
    percentage: 71,
  },
  {
    row: "Economic Relations Division, Ministry of Finance of the People's Republic of Bangladesh",
    parentRow: "Governmental Organization",
    column: "Malaria",
    value: 18428834.92,
    budget: 39145702.5,
    percentage: 47,
  },
  {
    row: "Ministry of Health of the Republic of Angola",
    parentRow: "Governmental Organization",
    column: "Malaria",
    value: 5844281.39,
    budget: 13470602.61,
    percentage: 43,
  },
  {
    row: "Nai Zindagi",
    parentRow: "Civil Society Organization",
    column: "HIV/AIDS",
    value: 32578424.38,
    budget: 40133979.52,
    percentage: 81,
  },
  {
    row: "Amref Health Africa",
    parentRow: "Civil Society Organization",
    column: "HIV/TB",
    value: 58327417.86,
    budget: 61574600.04,
    percentage: 95,
  },
  {
    row: "Ministry of Health of the Republic of Suriname",
    parentRow: "Governmental Organization",
    column: "Malaria",
    value: 3577690.81,
    budget: 6098652.39,
    percentage: 59,
  },
  {
    row: "Solidarity and Action Against The HIV Infection in India",
    parentRow: "Civil Society Organization",
    column: "HIV/AIDS",
    value: 19055229.16,
    budget: 34421973.48,
    percentage: 55,
  },
  {
    row: "National Agency for the Control of AIDS",
    parentRow: "Governmental Organization",
    column: "HIV/AIDS",
    value: 47084546.25,
    budget: 87223028.74,
    percentage: 54,
  },
  {
    row: "Centro de Colaboração em Saúde",
    parentRow: "Civil Society Organization",
    column: "HIV/TB",
    value: 79742972.45,
    budget: 84986556.01,
    percentage: 94,
  },
  {
    row: "Partners In Health",
    parentRow: "Civil Society Organization",
    column: "Tuberculosis",
    value: 4639519.64,
    budget: 5175000.13,
    percentage: 90,
  },
  {
    row: "Ministry of Public Health and Prevention",
    parentRow: "Governmental Organization",
    column: "Malaria",
    value: 10979416.54,
    budget: 40575212.21,
    percentage: 27,
  },
  {
    row: "Catholic Relief Services - United States Conference of Catholic Bishops",
    parentRow: "Civil Society Organization",
    column: "Multi-Component",
    value: 7053913,
    budget: 11789421.99,
    percentage: 60,
  },
  {
    row: "United Nations Development Programme",
    parentRow: "Multilateral Organization",
    column: "Malaria",
    value: 340775277.81,
    budget: 418641406.95,
    percentage: 81,
  },
  {
    row: "National Emergency Response Council on HIV and AIDS",
    parentRow: "Governmental Organization",
    column: "Malaria",
    value: 4284945.02,
    budget: 5445630.72,
    percentage: 79,
  },
  {
    row: "Cooperative Housing Foundation",
    parentRow: "Civil Society Organization",
    column: "Malaria",
    value: 6863746.53,
    budget: 8555550.24,
    percentage: 80,
  },
  {
    row: "Lubombo Spatial Development Initiative 2 NPC",
    parentRow: "Governmental Organization",
    column: "Malaria",
    value: 30272003.54,
    budget: 32172441.29,
    percentage: 94,
  },
  {
    row: "Programme National de Lutte contre la Tuberculose et la Lepre",
    parentRow: "Governmental Organization",
    column: "Tuberculosis",
    value: 823814.38,
    budget: 909810,
    percentage: 91,
  },
  {
    row: "Economic Relations Division, Ministry of Finance of the People's Republic of Bangladesh",
    parentRow: "Governmental Organization",
    column: "Tuberculosis",
    value: 105281695.75,
    budget: 143002004.85,
    percentage: 74,
  },
  {
    row: "Cambodia Ministry of Economy and Finance",
    parentRow: "Governmental Organization",
    column: "RSSH",
    value: 2042830.52,
    budget: 2569339.65,
    percentage: 80,
  },
  {
    row: "AngloGold Ashanti (Ghana) Malaria Control Limited",
    parentRow: "Private Sector",
    column: "Malaria",
    value: 42520917.55,
    budget: 43218041.91,
    percentage: 98,
  },
  {
    row: "Population Services International",
    parentRow: "Civil Society Organization",
    column: "HIV/AIDS",
    value: 16322731.5,
    budget: 17191036.41,
    percentage: 95,
  },
  {
    row: "Federación Red NICASALUD",
    parentRow: "Civil Society Organization",
    column: "Malaria",
    value: 11830765.57,
    budget: 12669836.24,
    percentage: 93,
  },
  {
    row: "Institute of Nutrition of Central America and Panama (INCAP)",
    parentRow: "Multilateral Organization",
    column: "HIV/AIDS",
    value: 34708986.56,
    budget: 46146009.25,
    percentage: 75,
  },
  {
    row: "Humanist Institute for Co-operation with Developing Countries (Hivos)",
    parentRow: "Civil Society Organization",
    column: "HIV/AIDS",
    value: 31495869.05,
    budget: 35648453.04,
    percentage: 88,
  },
  {
    row: "Plan International, Inc.",
    parentRow: "Civil Society Organization",
    column: "HIV/AIDS",
    value: 26539882.54,
    budget: 42442515.99,
    percentage: 63,
  },
  {
    row: "National Center For Disease Control and Public Health",
    parentRow: "Governmental Organization",
    column: "Tuberculosis",
    value: 11485936.58,
    budget: 12456677.02,
    percentage: 92,
  },
  {
    row: "East, Central and Southern Africa Health Community",
    parentRow: "Civil Society Organization",
    column: "Tuberculosis",
    value: 9926063.24,
    budget: 14520778.91,
    percentage: 68,
  },
  {
    row: "Alter Vida - Centro de Estudios y Formación para el Ecodesarrollo",
    parentRow: "Civil Society Organization",
    column: "Tuberculosis",
    value: 4009560.62,
    budget: 4161025.14,
    percentage: 96,
  },
  {
    row: "Ministry of Public Health, Hygiene and Prevention",
    parentRow: "Governmental Organization",
    column: "RSSH",
    value: 28356714,
    budget: 37667928.51,
    percentage: 75,
  },
  {
    row: "Plan International, Inc.",
    parentRow: "Civil Society Organization",
    column: "Multi-Component",
    value: 10829883.63,
    budget: 11382840.53,
    percentage: 95,
  },
  {
    row: "The National AIDS Secretariat of the Republic of The Gambia",
    parentRow: "Governmental Organization",
    column: "HIV/TB",
    value: 27141477.86,
    budget: 31610497.57,
    percentage: 86,
  },
  {
    row: "Pact, Inc.",
    parentRow: "Civil Society Organization",
    column: "HIV/TB",
    value: 9475563.5,
    budget: 12347559.23,
    percentage: 77,
  },
  {
    row: "Ministry of Health of the Lao People's Democratic Republic",
    parentRow: "Governmental Organization",
    column: "HIV/AIDS",
    value: 7181793.63,
    budget: 8627308.28,
    percentage: 83,
  },
  {
    row: "CARE INTERNATIONAL CAMEROON",
    parentRow: "Civil Society Organization",
    column: "HIV/TB",
    value: 6456066.83,
    budget: 16343976.4,
    percentage: 40,
  },
  {
    row: "Ministry of Health of the Republic of Zambia",
    parentRow: "Governmental Organization",
    column: "HIV/TB",
    value: 331786387.2,
    budget: 404303087.75,
    percentage: 82,
  },
  {
    row: "The AIDS Support Organisation (Uganda) Limited",
    parentRow: "Civil Society Organization",
    column: "HIV/TB",
    value: 43469125.2,
    budget: 58883004.36,
    percentage: 74,
  },
  {
    row: "Ministry of Health of Montenegro",
    parentRow: "Governmental Organization",
    column: "HIV/AIDS",
    value: 1049483.83,
    budget: 1432963.39,
    percentage: 73,
  },
  {
    row: "Plan International, Inc.",
    parentRow: "Civil Society Organization",
    column: "HIV/TB",
    value: 39887146.15,
    budget: 47995858.36,
    percentage: 83,
  },
  {
    row: "Kazakh scientific center of dermatology and infectious diseases of the Ministry of Health of the Republic of Kazakhstan",
    parentRow: "Governmental Organization",
    column: "HIV/AIDS",
    value: 10568096.37,
    budget: 11826100.6,
    percentage: 89,
  },
  {
    row: "Prévention Information Lutte contre le Sida",
    parentRow: "Civil Society Organization",
    column: "HIV/AIDS",
    value: 1169942.03,
    budget: 1243912.65,
    percentage: 94,
  },
  {
    row: "St. Petersburg charitable fund programs “Humanitarian action”",
    parentRow: "Civil Society Organization",
    column: "HIV/AIDS",
    value: 4731125.58,
    budget: 6744251.06,
    percentage: 70,
  },
  {
    row: "United Nations Development Programme",
    parentRow: "Multilateral Organization",
    column: "Tuberculosis",
    value: 77294888.86,
    budget: 104452093.93,
    percentage: 74,
  },
  {
    row: "United Nations Development Programme",
    parentRow: "Multilateral Organization",
    column: "HIV/TB",
    value: 240531618.95,
    budget: 324183107.12,
    percentage: 74,
  },
  {
    row: "SADC Malaria Elimination Eight Secretariat",
    parentRow: "Civil Society Organization",
    column: "Malaria",
    value: 18963237.33,
    budget: 21064858.54,
    percentage: 90,
  },
  {
    row: "Ministry of Health, Population and Hospital Reform of the People's Democratic Republic of Algeria",
    parentRow: "Governmental Organization",
    column: "HIV/AIDS",
    value: 4132996.05,
    budget: 4790620.45,
    percentage: 86,
  },
  {
    row: "Ministry of Health of the Republic of Rwanda",
    parentRow: "Governmental Organization",
    column: "Tuberculosis",
    value: 17571557.02,
    budget: 18401223.87,
    percentage: 95,
  },
  {
    row: "Ministère de la Santé Publique et de la Population (Unité de Gestion des Projets)",
    parentRow: "Governmental Organization",
    column: "RSSH",
    value: 10043835,
    budget: 19208100.94,
    percentage: 52,
  },
  {
    row: "Stichting Cordaid",
    parentRow: "Civil Society Organization",
    column: "HIV/TB",
    value: 354430475.99,
    budget: 419619039.27,
    percentage: 84,
  },
  {
    row: "Ministry of Health of the State of Eritrea",
    parentRow: "Governmental Organization",
    column: "Malaria",
    value: 30343873.29,
    budget: 38404948.57,
    percentage: 79,
  },
  {
    row: "Konsorsium Komunitas PENABULU-STPI",
    parentRow: "Civil Society Organization",
    column: "Tuberculosis",
    value: 26099787,
    budget: 39227666.69,
    percentage: 67,
  },
  {
    row: "Ministry of Health of the Democratic Socialist  Republic of Sri Lanka",
    parentRow: "Governmental Organization",
    column: "Tuberculosis",
    value: 4012739.78,
    budget: 7242253.12,
    percentage: 55,
  },
  {
    row: "Ministry of Health of the Republic of Guinea-Bissau",
    parentRow: "Governmental Organization",
    column: "HIV/TB",
    value: 40832637.59,
    budget: 54250981.03,
    percentage: 75,
  },
  {
    row: "Instituto Nicaragüense de Seguridad Social",
    parentRow: "Governmental Organization",
    column: "HIV/AIDS",
    value: 4027914.02,
    budget: 4166336.17,
    percentage: 97,
  },
  {
    row: "World Vision Malawi",
    parentRow: "Civil Society Organization",
    column: "HIV/TB",
    value: 13534644,
    budget: 15097235.43,
    percentage: 90,
  },
  {
    row: "Ministry of Health",
    parentRow: "Governmental Organization",
    column: "Multi-Component",
    value: 85644459.71,
    budget: 168113984.34,
    percentage: 51,
  },
  {
    row: "Governmental Organization",
    column: "Tuberculosis",
    value: 1600068845.64,
    budget: 2079227333.0499992,
    percentage: 77,
  },
  {
    row: "Governmental Organization",
    column: "HIV/AIDS",
    value: 3825267188.1999993,
    budget: 4691213909.3099985,
    percentage: 82,
  },
  {
    row: "Governmental Organization",
    column: "Malaria",
    value: 3407505277.799999,
    budget: 4017384906.439999,
    percentage: 85,
  },
  {
    row: "Governmental Organization",
    column: "HIV/TB",
    value: 2238846880.6,
    budget: 2793301037.3600006,
    percentage: 80,
  },
  {
    row: "Governmental Organization",
    column: "RSSH",
    value: 274967189.67,
    budget: 632456941.53,
    percentage: 43,
  },
  {
    row: "Governmental Organization",
    column: "Multi-Component",
    value: 200081142.01999998,
    budget: 343888811.38,
    percentage: 58,
  },
  {
    row: "Civil Society Organization",
    column: "Tuberculosis",
    value: 892692738.2199999,
    budget: 1093172179.02,
    percentage: 82,
  },
  {
    row: "Civil Society Organization",
    column: "HIV/AIDS",
    value: 1120168925.9499998,
    budget: 1341681259.3400002,
    percentage: 83,
  },
  {
    row: "Civil Society Organization",
    column: "Malaria",
    value: 2275243834.11,
    budget: 2580089817.3899994,
    percentage: 88,
  },
  {
    row: "Civil Society Organization",
    column: "HIV/TB",
    value: 1664466415.38,
    budget: 2058352117.9900002,
    percentage: 81,
  },
  {
    row: "Civil Society Organization",
    column: "RSSH",
    value: 35875534,
    budget: 42996203.21,
    percentage: 83,
  },
  {
    row: "Civil Society Organization",
    column: "Multi-Component",
    value: 17883796.630000003,
    budget: 23172262.52,
    percentage: 77,
  },
  {
    row: "Private Sector",
    column: "Tuberculosis",
    value: 67571100.91,
    budget: 83445835.37,
    percentage: 81,
  },
  {
    row: "Private Sector",
    column: "HIV/AIDS",
    value: 18185138,
    budget: 20732077.39,
    percentage: 88,
  },
  {
    row: "Private Sector",
    column: "Malaria",
    value: 138066587.53,
    budget: 152356353.81,
    percentage: 91,
  },
  {
    row: "Private Sector",
    column: "HIV/TB",
    value: 13281339.56,
    budget: 18283889.09,
    percentage: 73,
  },
  {
    row: "Private Sector",
    column: "RSSH",
    value: 0,
    budget: 0,
    percentage: 120,
  },
  {
    row: "Private Sector",
    column: "Multi-Component",
    value: 0,
    budget: 0,
    percentage: 120,
  },
  {
    row: "Multilateral Organization",
    column: "Tuberculosis",
    value: 245598300.45,
    budget: 303342188.65999997,
    percentage: 81,
  },
  {
    row: "Multilateral Organization",
    column: "HIV/AIDS",
    value: 1096473485.6499999,
    budget: 1326883440.52,
    percentage: 83,
  },
  {
    row: "Multilateral Organization",
    column: "Malaria",
    value: 855691830.23,
    budget: 1102572102.3799999,
    percentage: 78,
  },
  {
    row: "Multilateral Organization",
    column: "HIV/TB",
    value: 262979406.04999998,
    budget: 354251787.61,
    percentage: 74,
  },
  {
    row: "Multilateral Organization",
    column: "RSSH",
    value: 0,
    budget: 0,
    percentage: 120,
  },
  {
    row: "Multilateral Organization",
    column: "Multi-Component",
    value: 237680845.7,
    budget: 347894235.96000004,
    percentage: 68,
  },
];

export const STORY_DATA_VARIANT_2: HeatmapDataItem[] = [
  {
    column: "2023",
    row: "HIV/AIDS",
    value: "Eligible",
    diseaseBurden: "NH",
  },
  {
    column: "2023",
    row: "Malaria",
    value: "Eligible",
    diseaseBurden: "H",
  },
  {
    column: "2023",
    row: "Tuberculosis",
    value: "Eligible",
    diseaseBurden: "H",
  },
  {
    column: "2023",
    row: "Income Level",
    value: "",
    diseaseBurden: "Low",
  },
  {
    column: "2022",
    row: "HIV/AIDS",
    value: "Eligible",
    diseaseBurden: "NH",
  },
  {
    column: "2022",
    row: "Malaria",
    value: "Eligible",
    diseaseBurden: "H",
  },
  {
    column: "2022",
    row: "Tuberculosis",
    value: "Eligible",
    diseaseBurden: "H",
  },
  {
    column: "2022",
    row: "Income Level",
    value: "",
    diseaseBurden: "Low",
  },
  {
    column: "2021",
    row: "HIV/AIDS",
    value: "Eligible",
    diseaseBurden: "NH",
  },
  {
    column: "2021",
    row: "Malaria",
    value: "Eligible",
    diseaseBurden: "H",
  },
  {
    column: "2021",
    row: "Tuberculosis",
    value: "Eligible",
    diseaseBurden: "H",
  },
  {
    column: "2021",
    row: "Income Level",
    value: "",
    diseaseBurden: "Low",
  },
  {
    column: "2020",
    row: "HIV/AIDS",
    value: "Eligible",
    diseaseBurden: "NH",
  },
  {
    column: "2020",
    row: "Malaria",
    value: "Eligible",
    diseaseBurden: "H",
  },
  {
    column: "2020",
    row: "Tuberculosis",
    value: "Eligible",
    diseaseBurden: "H",
  },
  {
    column: "2020",
    row: "Income Level",
    value: "",
    diseaseBurden: "Low",
  },
  {
    column: "2019",
    row: "HIV/AIDS",
    value: "Eligible",
    diseaseBurden: "NH",
  },
  {
    column: "2019",
    row: "Malaria",
    value: "Eligible",
    diseaseBurden: "H",
  },
  {
    column: "2019",
    row: "Tuberculosis",
    value: "Eligible",
    diseaseBurden: "H",
  },
  {
    column: "2019",
    row: "Income Level",
    value: "",
    diseaseBurden: "Low",
  },
  {
    column: "2018",
    row: "HIV/AIDS",
    value: "Eligible",
    diseaseBurden: "M",
  },
  {
    column: "2018",
    row: "Malaria",
    value: "Eligible",
    diseaseBurden: "M",
  },
  {
    column: "2018",
    row: "Tuberculosis",
    value: "Eligible",
    diseaseBurden: "S",
  },
  {
    column: "2018",
    row: "Income Level",
    value: "",
    diseaseBurden: "Low",
  },
  {
    column: "2017",
    row: "HIV/AIDS",
    value: "Eligible",
    diseaseBurden: "M",
  },
  {
    column: "2017",
    row: "Malaria",
    value: "Eligible",
    diseaseBurden: "M",
  },
  {
    column: "2017",
    row: "Tuberculosis",
    value: "Eligible",
    diseaseBurden: "S",
  },
  {
    column: "2017",
    row: "Income Level",
    value: "",
    diseaseBurden: "Low",
  },
  {
    column: "2016",
    row: "HIV/AIDS",
    value: "Eligible",
    diseaseBurden: "M",
  },
  {
    column: "2016",
    row: "Malaria",
    value: "Eligible",
    diseaseBurden: "M",
  },
  {
    column: "2016",
    row: "RSSH",
    value: "Eligible",
    diseaseBurden: "NA",
  },
  {
    column: "2016",
    row: "Tuberculosis",
    value: "Eligible",
    diseaseBurden: "S",
  },
  {
    column: "2016",
    row: "Income Level",
    value: "",
    diseaseBurden: "Low",
  },
  {
    column: "2015",
    row: "HIV/AIDS",
    value: "Eligible",
    diseaseBurden: "M",
  },
  {
    column: "2015",
    row: "Malaria",
    value: "Eligible",
    diseaseBurden: "M",
  },
  {
    column: "2015",
    row: "RSSH",
    value: "Eligible",
    diseaseBurden: "NA",
  },
  {
    column: "2015",
    row: "Tuberculosis",
    value: "Eligible",
    diseaseBurden: "S",
  },
  {
    column: "2015",
    row: "Income Level",
    value: "",
    diseaseBurden: "Low",
  },
  {
    column: "2014",
    row: "HIV/AIDS",
    value: "Eligible",
    diseaseBurden: "M",
  },
  {
    column: "2014",
    row: "Malaria",
    value: "Eligible",
    diseaseBurden: "M",
  },
  {
    column: "2014",
    row: "RSSH",
    value: "Eligible",
    diseaseBurden: "NA",
  },
  {
    column: "2014",
    row: "Tuberculosis",
    value: "Eligible",
    diseaseBurden: "S",
  },
  {
    column: "2014",
    row: "Income Level",
    value: "",
    diseaseBurden: "Low",
  },
  {
    column: "2013",
    row: "HIV/AIDS",
    value: "Eligible",
    diseaseBurden: "H",
  },
  {
    column: "2013",
    row: "Malaria",
    value: "Eligible",
    diseaseBurden: "M",
  },
  {
    column: "2013",
    row: "RSSH",
    value: "Eligible",
    diseaseBurden: "NA",
  },
  {
    column: "2013",
    row: "Tuberculosis",
    value: "Eligible",
    diseaseBurden: "S",
  },
  {
    column: "2013",
    row: "Income Level",
    value: "",
    diseaseBurden: "Low",
  },
  {
    column: "2012",
    row: "HIV/AIDS",
    value: "Eligible",
    diseaseBurden: "H",
  },
  {
    column: "2012",
    row: "Malaria",
    value: "Eligible",
    diseaseBurden: "M",
  },
  {
    column: "2012",
    row: "Tuberculosis",
    value: "Not Eligible",
    diseaseBurden: "S",
  },
  {
    column: "2012",
    row: "Income Level",
    value: "",
    diseaseBurden: "Low",
  },
  {
    column: "2011",
    row: "HIV/AIDS",
    value: "Eligible",
    diseaseBurden: "H",
  },
  {
    column: "2011",
    row: "Malaria",
    value: "Eligible",
    diseaseBurden: "M",
  },
  {
    column: "2011",
    row: "RSSH",
    value: "Not Eligible",
    diseaseBurden: "NA",
  },
  {
    column: "2011",
    row: "Tuberculosis",
    value: "Not Eligible",
    diseaseBurden: "S",
  },
  {
    column: "2011",
    row: "Income Level",
    value: "",
    diseaseBurden: "Low",
  },
  {
    column: "2010",
    row: "HIV/AIDS",
    value: "Eligible",
    diseaseBurden: "NA",
  },
  {
    column: "2010",
    row: "Malaria",
    value: "Eligible",
    diseaseBurden: "NA",
  },
  {
    column: "2010",
    row: "Tuberculosis",
    value: "Eligible",
    diseaseBurden: "NA",
  },
  {
    column: "2010",
    row: "Income Level",
    value: "",
    diseaseBurden: "Low",
  },
  {
    column: "2009",
    row: "HIV/AIDS",
    value: "Eligible",
    diseaseBurden: "NA",
  },
  {
    column: "2009",
    row: "Malaria",
    value: "Eligible",
    diseaseBurden: "NA",
  },
  {
    column: "2009",
    row: "Tuberculosis",
    value: "Eligible",
    diseaseBurden: "NA",
  },
  {
    column: "2009",
    row: "Income Level",
    value: "",
    diseaseBurden: "Low",
  },
  {
    column: "2008",
    row: "HIV/AIDS",
    value: "Eligible",
    diseaseBurden: "NA",
  },
  {
    column: "2008",
    row: "Malaria",
    value: "Eligible",
    diseaseBurden: "NA",
  },
  {
    column: "2008",
    row: "Tuberculosis",
    value: "Eligible",
    diseaseBurden: "NA",
  },
  {
    column: "2008",
    row: "Income Level",
    value: "",
    diseaseBurden: "Low",
  },
  {
    column: "2007",
    row: "HIV/AIDS",
    value: "Eligible",
    diseaseBurden: "NA",
  },
  {
    column: "2007",
    row: "Malaria",
    value: "Eligible",
    diseaseBurden: "NA",
  },
  {
    column: "2007",
    row: "Tuberculosis",
    value: "Eligible",
    diseaseBurden: "NA",
  },
  {
    column: "2007",
    row: "Income Level",
    value: "",
    diseaseBurden: "Low",
  },
  {
    column: "2006",
    row: "HIV/AIDS",
    value: "Eligible",
    diseaseBurden: "NA",
  },
  {
    column: "2006",
    row: "Malaria",
    value: "Eligible",
    diseaseBurden: "NA",
  },
  {
    column: "2006",
    row: "Tuberculosis",
    value: "Eligible",
    diseaseBurden: "NA",
  },
  {
    column: "2006",
    row: "Income Level",
    value: "",
    diseaseBurden: "Low",
  },
  {
    column: "2005",
    row: "HIV/AIDS",
    value: "Eligible",
    diseaseBurden: "NA",
  },
  {
    column: "2005",
    row: "Malaria",
    value: "Eligible",
    diseaseBurden: "NA",
  },
  {
    column: "2005",
    row: "Tuberculosis",
    value: "Eligible",
    diseaseBurden: "NA",
  },
  {
    column: "2005",
    row: "Income Level",
    value: "",
    diseaseBurden: "Low",
  },
  {
    column: "2004",
    row: "HIV/AIDS",
    value: "Eligible",
    diseaseBurden: "NA",
  },
  {
    column: "2004",
    row: "Malaria",
    value: "Eligible",
    diseaseBurden: "NA",
  },
  {
    column: "2004",
    row: "Tuberculosis",
    value: "Eligible",
    diseaseBurden: "NA",
  },
  {
    column: "2004",
    row: "Income Level",
    value: "",
    diseaseBurden: "Low",
  },
  {
    column: "2003",
    row: "HIV/AIDS",
    value: "Eligible",
    diseaseBurden: "NA",
  },
  {
    column: "2003",
    row: "Malaria",
    value: "Eligible",
    diseaseBurden: "NA",
  },
  {
    column: "2003",
    row: "Tuberculosis",
    value: "Eligible",
    diseaseBurden: "NA",
  },
  {
    column: "2003",
    row: "Income Level",
    value: "",
    diseaseBurden: "Low",
  },
];

export const LEGENDS = [
  {
    label: "> 85%",
    color: "#5BA053",
  },
  {
    label: "75% - 84%",
    color: "#8CCD81",
  },
  {
    label: "65% - 74%",
    color: "#ECC854",
  },
  {
    label: "< 65%",
    color: "#EE5D52",
  },
  {
    label: "> 120% outlier",
    color: "#DADADA",
  },
  {
    label: "N/A",
    color: "#FFFFFF",
  },
];
