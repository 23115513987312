export const CHART_1_DROPDOWN_ITEMS = [
  {
    value: "Grouped Component",
    label: "Grouped Component",
  },
];

export const CHART_2_DROPDOWN_ITEMS = [
  { label: "Investment Landscape", value: "Investment Landscape" },
  { label: "Modules & Interventions", value: "Modules & Interventions" },
];
